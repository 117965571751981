import { breakX } from "../brands";

export default [
  {
    product: "Original 17",
    module: "Módulo: 10 Unidades",
    image: "/img/categories/cigarrilhas/break/original/product.png",
    barcode: "8720400388820",
    brand: breakX,
    filters: {
      tamanho: "regular",
      sabor: "original",
      filtro: "comFiltro",
    },
    properties: [],
  },
  {
    product: "Blue 17",
    module: "Módulo: 10 Unidades",
    image: "/img/categories/cigarrilhas/break/blue/product.png",
    barcode: "8720400388851",
    brand: breakX,
    filters: {
      tamanho: "regular",
      sabor: "suave",
      filtro: "comFiltro",
    },
    properties: [],
  },
  {
    product: "Silver 17",
    module: "Módulo: 10 Unidades",
    image: "/img/categories/cigarrilhas/break/silver/product.png",
    barcode: "8720400398447",
    brand: breakX,
    filters: {
      tamanho: "regular",
      sabor: "extraSuave",
      filtro: "comFiltro",
    },
    properties: [],
  },
  {
    product: "Green 17",
    module: "Módulo: 10 Unidades",
    image: "/img/categories/cigarrilhas/break/green/product.png",
    barcode: "8720400388882",
    brand: breakX,
    filters: {
      tamanho: "regular",
      sabor: "mentol",
      filtro: "comFiltro",
    },
    properties: [],
  },
  // {
  //   product: "Action Blue 10",
  //   module: "Módulo: 10 Unidades",
  //   image: "/img/categories/cigarrilhas/break/actionBlue10/product.png",
  //   barcode: "8720400489008",
  //   brand: breakX,
  //   filters: {
  //     tamanho: "regular",
  //     sabor: "mentol",
  //     filtro: "comFiltro",
  //   },
  //   properties: [],
  // },
  // {
  //   product: "Action Blue 17",
  //   module: "Módulo: 10 Unidades",
  //   image: "/img/categories/cigarrilhas/break/actionBlue17/product.png",
  //   barcode: "8720400526499",
  //   brand: breakX,
  //   filters: {
  //     tamanho: "regular",
  //     sabor: "mentol",
  //     filtro: "comFiltro",
  //   },
  //   properties: [],
  // },
];
