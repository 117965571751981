<template>

    <div class="home vh-100 w-100 pb-3 d-flex flex-column justify-content-between align-items-center position-relative">
        <!-- LOGO -->
        <div class="container logo-container">
            <div class="row d-flex flex-column align-items-center">
                <div class="navbar">
                    <img src="/img/logo-stg.png" alt="logo" @click="goToIntro">
                </div>
            </div>
        </div>
        <!-- END LOGO -->
        <!-- LINKS -->
        <div class="container-fluid links-container pl-6 d-flex flex-column justify-content-around flex-grow-1 text-left">
            <!-- CIGARILHAS -->
            <div class="row">
                <div class="col-12 px-0 d-flex justify-content-between align-items-center" :class="{ clicked: categoryClicked == 'cigarrilhas' }" style="overflow:hidden;">
                    <h1 class="text-uppercase mb-0 grey-color" @click="goTo('cigarrilhas')">CIGARRILHAS</h1>
                    <div class="cigarrilha-container">
                        <img src="/img/categories/cigarrilhas/cigarrilhas.png" alt="Cigarrilhas">
                    </div>
                </div>
            </div>
            <!-- END CIGARRILHAS -->
            <!-- CACHIMBO -->
            <div class="row">
                <div class="col-12 px-0 d-flex justify-content-between align-items-center" :class="{ clicked: categoryClicked == 'cachimbo' }" style="overflow:hidden;">
                    <h1 class="text-uppercase mb-0 grey-color" @click="goTo('cachimbo')">CACHIMBO</h1>
                    <div class="cachimbo-container">
                        <img src="/img/categories/cachimbo/cachimbo.png" alt="Cachimbo">
                    </div>
                </div>
            </div>
            <!-- END CACHIMBO -->
            <!-- CHARUTO -->
            <div class="row">
                <div class="col-12 px-0 d-flex justify-content-between align-items-center" :class="{ clicked: categoryClicked == 'charuto' }" style="overflow:hidden;">
                    <h1 class="text-uppercase mb-0 grey-color" @click="goTo('charuto')">CHARUTO</h1>
                    <div class="charuto-container">
                        <img src="/img/categories/charuto/charuto.png" alt="Charuto">
                    </div>
                </div>
            </div>
            <!-- END CHARUTO -->
            <!-- TABACO DE ENROLAR -->
            <div class="row">
                <div class="col-12 px-0 d-flex justify-content-between align-items-center" :class="{ clicked: categoryClicked == 'enrolar' }" style="overflow:hidden;">
                    <h1 class="text-uppercase mb-0 grey-color" @click="goTo('enrolar')">TABACO DE ENROLAR</h1>
                    <div class="enrolar-container">
                        <img src="/img/categories/enrolar/enrolar.png" alt="Tabaco de enrolar">
                    </div>
                </div>
            </div>
            <!-- END TABACO DE ENROLAR -->
        </div>
        <!-- END LINKS -->
        <!-- BUTTON -->
        <div class="container button-container btn-animated">
            <div class="row">
                <div class="col-12">
                    <!-- <router-link tag="a" class="white-color white-border" to="/portfolio">Ver portfólio completo</router-link> -->
                    <a class="white-color white-border bttn" :class="{ draw: btnIsActive }" @click="goToPortfolio">Ver portfólio completo</a>
                </div>
            </div>
        </div>
        <!-- END BUTTON -->
        <transition name="fade">
            <loading v-if="showLoading" :showloadingicon="controlLoadingIcon" />
        </transition>
    </div>

</template>

<script>

import loading from '@/components/Loading.vue';

export default {
    name: 'home',
    components: {
        loading,
    },
    data: function() {
        return {
            categoryClicked: null,
            btnIsActive: false,
            showLoading: false,
            controlLoadingIcon: false,
        }
    },
    methods: {
        goTo: function(category) {
            this.categoryClicked = category;
            this.controlLoadingIcon = true;
            this.showLoading = true;

            setTimeout(() => {
                this.$router.push({ name:'category', params: { category: category } });
            }, 550);
        },
        goToIntro: function () {
            this.$router.push({ name:'intro' });
        },
        goToPortfolio: function () {
          this.btnIsActive = true;
          this.showLoading = true;
          setTimeout(() => {
            this.$router.push({ name:'portfolio' });
          }, 350);
        },
    },
};

</script>

<style lang="scss" scoped>

    .loading {
        background-color: transparent;
    }

    .home {
        background: url('/img/bg-home.png') center/cover no-repeat;
        position: relative;
        
        &:before {
            content: '';
            display: block;
            position: absolute;
            top: 0;
            bottom: 0;
            left: 0;
            right: 0;
            background-color: rgba(0,0,0,0.6);
            z-index: 0;
        }

        /* LOGO  */
        .logo-container img {
            // max-width: 200px;
            height: 60px;
        }
        /* LINKS */
        .links-container {
            /* TITLES */
            h1 {
                font-size: 4rem;
                font-weight: bold;
                line-height: 1em;
                margin-top: 0.5rem;
                transition: color 0.3s;
            }
            .clicked h1 {
                color: $white;
            }
            /* CIGARRILHA */
            .cigarrilha-container {
                margin-right: -17rem;
                transition: margin-right 0.3s;
                img {
                    max-height: 3.5rem;
                }
            }
            .clicked .cigarrilha-container {
                margin-right: -12rem;
            }
            /* CHARUTO */
            .charuto-container {
                margin-right: -16.5rem;
                transition: margin-right 0.3s;
                img {
                    max-height: 4.5rem;
                }
            }
            .clicked .charuto-container {
                margin-right: -11.5rem;
            }
            /* CACHIMBO */
            .cachimbo-container {
                margin-right: -11rem;
                transition: margin-right 0.3s;
                img {
                    max-height: 6rem;
                }
            }
            .clicked .cachimbo-container {
                margin-right: -6rem;
            }
            /* TABACO DE ENROLAR */
            .enrolar-container {
                margin-right: -9rem;
                transition: margin-right 0.3s;
                img {
                    max-height: 5rem;
                }
            }
            .clicked .enrolar-container {
                margin-right: -4rem;
            }
        }
        // BUTTON
        .btn-animated {
            animation: moveInBottom .5s ease-out 0.3s;
            -webkit-animation: moveInBottom .5s ease-out 0.3s;
            animation-fill-mode: backwards;
        }
    }

    // Extra small devices (portrait phones, less than 576px)
    @media (max-width: 575.98px) {
        .home {
            /* LOGO  */
            .logo-container img {
                // max-width: 150px;
                height: 40px;
            }
            .links-container {
                h1 {
                    font-size: 2rem;
                }
                /* CIGARRILHA */
                .cigarrilha-container {
                    margin-right: -7rem;
                    img {
                        max-height: 1.3rem;
                    }
                }
                .clicked .cigarrilha-container {
                    margin-right: -4rem;
                }
                /* CHARUTO */
                .charuto-container {
                    margin-right: -5.5rem;
                    img {
                        max-height: 1.5rem;
                    }
                }
                .clicked .charuto-container {
                    margin-right: -4rem;
                }
                /* CACHIMBO */
                .cachimbo-container {
                    margin-right: -6rem;
                    img {
                        max-height: 2.5rem;
                    }
                }
                .clicked .cachimbo-container {
                    margin-right: -4rem;
                }
                /* TABACO DE ENROLAR */
                .enrolar-container {
                    margin-right: -6.5rem;
                    img {
                        max-height: 2.5rem;
                    }
                }
                .clicked .enrolar-container {
                    margin-right: -4rem;
                }
            }
        }
    }

    // Small devices (landscape phones, 576px and up)
    @media (min-width: 568px) and (max-width: 767.98px) and (orientation: landscape), (max-width: 812px) and (max-height: 375px) and (orientation: landscape) {
        .home {
            /* LOGO  */
            .logo-container img {
                max-width: 150px;
                height: 40px;
            }
            .links-container {
                margin-bottom: 1rem;
                h1 {
                    font-size: 2rem;
                }
                /* CIGARRILHA */
                .cigarrilha-container {
                    margin-right: -7rem;
                    img {
                        max-height: 1.3rem;
                    }
                }
                .clicked .cigarrilha-container {
                    margin-right: -4rem;
                }
                /* CHARUTO */
                .charuto-container {
                    margin-right: -5.5rem;
                    img {
                        max-height: 1.5rem;
                    }
                }
                .clicked .charuto-container {
                    margin-right: -4rem;
                }
                /* CACHIMBO */
                .cachimbo-container {
                    margin-right: -6rem;
                    img {
                        max-height: 2.5rem;
                    }
                }
                .clicked .cachimbo-container {
                    margin-right: -4rem;
                }
                /* TABACO DE ENROLAR */
                .enrolar-container {
                    margin-right: -6.5rem;
                    img {
                        max-height: 2.5rem;
                    }
                }
                .clicked .enrolar-container {
                    margin-right: -4rem;
                }
            }
        }
    }

</style>
