import { alsboBlack } from '../brands';

export default [
  {
    product: 'black 35g',
    module: 'Módulo: 5 Onças',
    image: '/img/categories/cachimbo/alsboBlack/35g/product.png',
    barcode: '5710840140709',
    brand: alsboBlack,
    filters: {
      corpo: '2',
      sabor: '3',
    },
    properties: [
        { key: "Nota de aroma", value: "2" },
    ],
  },
];
