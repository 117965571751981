<template>

    <div class="menu pt-2 pb-3 d-flex flex-column position-fixed">
        <div class="menu-content d-flex flex-column justify-content-between flex-grow-1">
            <!-- CLOSE BTN -->
            <div class="close-btn-container d-flex align-items-center pl-3" style="height:56px;">
                <div class="close-btn" @click="$emit('closeMenu')">
                    <img class="menu-icon" src="/img/close.png" alt="Fechar">
                </div>
            </div>
            <!-- END CLOSE BTN -->
            <!-- LINKS -->
            <div class="container-fluid links-container d-flex flex-column justify-content-around flex-grow-1 text-left">
                <!-- CIGARILHAS -->
                <div class="row bounceInDown">
                    <div class="col-12 d-flex justify-content-between align-items-center" :class="{ clicked: categoryClicked == 'cigarrilhas' }">
                        <h1 class="text-uppercase mb-0 grey-color" @click="goTo('cigarrilhas')">CIGARRILHAS</h1>
                        <div class="cigarrilha-container image-container">
                            <img src="/img/categories/cigarrilhas/cigarrilhas.png" alt="Cigarrilhas">
                        </div>
                    </div>
                </div>
                <!-- END CIGARRILHAS -->
                <!-- CACHIMBO -->
                <div class="row bounceInDown">
                    <div class="col-12 d-flex justify-content-between align-items-center" :class="{ clicked: categoryClicked == 'cachimbo' }">
                        <h1 class="text-uppercase mb-0 grey-color" @click="goTo('cachimbo')">CACHIMBO</h1>
                        <div class="cachimbo-container image-container">
                            <img src="/img/categories/cachimbo/cachimbo.png" alt="Cachimbo">
                        </div>
                    </div>
                </div>
                <!-- END CACHIMBO -->
                <!-- CHARUTO -->
                <div class="row bounceInDown">
                    <div class="col-12 d-flex justify-content-between align-items-center" :class="{ clicked: categoryClicked == 'charuto' }">
                        <h1 class="text-uppercase mb-0 grey-color" @click="goTo('charuto')">CHARUTO</h1>
                        <div class="charuto-container image-container">
                            <img src="/img/categories/charuto/charuto.png" alt="Charuto">
                        </div>
                    </div>
                </div>
                <!-- END CHARUTO -->
                <!-- TABACO DE ENROLAR -->
                <div class="row bounceInDown">
                    <div class="col-12 d-flex justify-content-between align-items-center" :class="{ clicked: categoryClicked == 'enrolar' }">
                        <h1 class="text-uppercase mb-0 grey-color" @click="goTo('enrolar')">TABACO DE ENROLAR</h1>
                        <div class="enrolar-container image-container">
                            <img src="/img/categories/enrolar/enrolar.png" alt="Tabaco de enrolar">
                        </div>
                    </div>
                </div>
                <!-- END TABACO DE ENROLAR -->
            </div>
            <!-- END LINKS -->
            <!-- BUTTON -->
            <div class="container button-container btn-animated">
                <div class="row">
                    <div class="col-12">
                        <a class="grey-color grey-border" :class="{ draw: btnIsActive }" @click="goToPortfolio()">Ver portfólio completo</a>
                    </div>
                </div>
            </div>
            <!-- END BUTTON -->
        </div>
        <transition name="fade">
            <loading v-if="showLoading" :showloadingicon="controlLoadingIcon" />
        </transition>
    </div>

</template>

<script>

import loading from '@/components/Loading.vue';

export default {
    name: 'fullmenu',
    components: {
        loading,
    },
    data: function() {
        return {
            categoryClicked: null,
            btnIsActive: false,
            showLoading: false,
            controlLoadingIcon: false,
        }
    },
    methods: {
        goTo: function(category) {
            this.categoryClicked = category;
            this.controlLoadingIcon = true;
            this.showLoading = true;

            setTimeout(() => {
                if(this.$router.currentRoute.name === 'category' && this.$router.currentRoute.params.category === category) {

                } else {
                    this.$router.push({ name:'category', params: { category: category } });
                }
                this.$emit('closeMenu');
            }, 550);
        },
        goToPortfolio: function() {
            this.btnIsActive = true;
            this.showLoading = true;

            setTimeout(() => {
                if(this.$router.currentRoute.name === 'portfolio') {

                } else {
                    this.$router.push({ name:'portfolio' });
                }
                this.$emit('closeMenu');
            }, 350);
        },
    },
};

</script>

<style scoped lang="scss">

.menu {
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    z-index: 100;
    background-color: rgba(0,0,0,0.9);
    color: $grey;
    .menu-content {
        /* CLOSE */
        .close-btn-container {
            img.menu-icon {
                height: 1.8rem;
            }
        }
        /* LINKS */
        .links-container {
            padding-left: 3rem;
            /* TITLES */
            h1 {
                font-size: 3rem;
                font-weight: bold;
                line-height: 1em;
                margin-top: 0.5rem;
                transition: color 0.3s;
            }
            .clicked h1 {
                color: $white;
            }
            /* CIGARRILHA */
            .cigarrilha-container {
                margin-right: -10rem;
                transition: margin-right 0.3s;
                img {
                    max-height: 2rem;
                }
            }
            .clicked .cigarrilha-container {
                margin-right: -5rem;
            }
            /* CHARUTO */
            .charuto-container {
                margin-right: -11.5rem;
                transition: margin-right 0.3s;
                img {
                    max-height: 3rem;
                }
            }
            .clicked .charuto-container {
                margin-right: -6.5rem;
            }
            /* CACHIMBO */
            .cachimbo-container {
                margin-right: -8rem;
                transition: margin-right 0.3s;
                img {
                    max-height: 4rem;
                }
            }
            .clicked .cachimbo-container {
                margin-right: -3rem;
            }
            /* TABACO DE ENROLAR */
            .enrolar-container {
                margin-right: -9rem;
                transition: margin-right 0.3s;
                img {
                    max-height: 4rem;
                }
            }
            .clicked .enrolar-container {
                margin-right: -4rem;
            }
        }
    }
}

// Extra small devices (portrait phones, less than 576px)
@media (max-width: 575.98px) {
    .menu {
        .menu-content {
            /* CLOSE */
            .close-btn-container {
                justify-content: center;
                img.menu-icon {
                    height: 1rem;
                }
            }
            /* LINKS */
            .links-container {
                padding-left: 0;
                .row {
                    .col-12 {
                        position: relative;
                    }
                }
                /* TITLES */
                h1 {
                    font-size: 2rem;
                    margin: 0.2rem 2rem 0;
                    position: absolute;
                    left: 0;
                    right: 0;
                    text-align: center;
                }
                .clicked h1 {
                    color: $white;
                }
                .image-container {
                    flex-grow: 1;
                    text-align: right;
                }
                /* CIGARRILHA */
                .cigarrilha-container {
                    margin-right: -6.5rem;
                    img {
                        max-height: 1rem;
                    }
                }
                .clicked .cigarrilha-container {
                    margin-right: -6.5rem;
                }
                /* CHARUTO */
                .charuto-container {
                    margin-right: -5.5rem;
                    img {
                        max-height: 1.2rem;
                    }
                }
                .clicked .charuto-container {
                    margin-right: -5.5rem;
                }
                /* CACHIMBO */
                .cachimbo-container {
                    margin-right: -5.5rem;
                    img {
                        max-height: 2rem;
                    }
                }
                .clicked .cachimbo-container {
                    margin-right: -5.5rem;
                }
                /* TABACO DE ENROLAR */
                .enrolar-container {
                    margin-right: -6.5rem;
                    img {
                        max-height: 2rem;
                    }
                }
                .clicked .enrolar-container {
                    margin-right: -6.5rem;
                }
            }
        }
    }
}

// Small devices (landscape phones, 576px and up)
@media (min-width: 568px) and (max-width: 767.98px) and (orientation: landscape), (max-width: 812px) and (max-height: 375px) and (orientation: landscape) {
    .menu {
        .menu-content {
            /* CLOSE */
            .close-btn-container {
                justify-content: center;
                margin-top: -0.5rem;
                img.menu-icon {
                    height: 1rem;
                }
            }
            /* LINKS */
            .links-container {
                padding-left: 0;
                margin-bottom: 1rem;
                .row {
                    .col-12 {
                        position: relative;
                    }
                }
                /* TITLES */
                h1 {
                    font-size: 2rem;
                    margin: 0.2rem 2rem 0;
                    position: absolute;
                    left: 0;
                    right: 0;
                    text-align: center;
                }
                .clicked h1 {
                    color: $white;
                }
                .image-container {
                    flex-grow: 1;
                    text-align: right;
                }
                /* CIGARRILHA */
                .cigarrilha-container {
                    margin-right: -6.5rem;
                    img {
                        max-height: 1rem;
                    }
                }
                .clicked .cigarrilha-container {
                    margin-right: -6.5rem;
                }
                /* CHARUTO */
                .charuto-container {
                    margin-right: -5.5rem;
                    img {
                        max-height: 1.2rem;
                    }
                }
                .clicked .charuto-container {
                    margin-right: -5.5rem;
                }
                /* CACHIMBO */
                .cachimbo-container {
                    margin-right: -5.5rem;
                    img {
                        max-height: 2rem;
                    }
                }
                .clicked .cachimbo-container {
                    margin-right: -5.5rem;
                }
                /* TABACO DE ENROLAR */
                .enrolar-container {
                    margin-right: -6.5rem;
                    img {
                        max-height: 2rem;
                    }
                }
                .clicked .enrolar-container {
                    margin-right: -6.5rem;
                }
            }
        }
    }
}

</style>
