import Vue from 'vue'
import Router from 'vue-router'

// Page content
import Intro from './views/Intro.vue';
import Home from './views/Home.vue';
import Portfolio from './views/Portfolio.vue';
import Category from './views/Category.vue';
import Brand from './views/Brand.vue';
import BrandProducts from './views/BrandProducts.vue';

// Fallback page
import NotFound from './views/NotFound.vue';

Vue.use(Router)

// export default new Router({
//   routes: [
//     {
//       path: '/',
//       name: 'Home',
//       component: Home
//     },
//     {
//       path: '/page-1',
//       name: 'Page 1',
//       component: Page1
//     },
//     {
//       path: '/fullmenu',
//       name: 'Fullmenu',
//       component: Fullmenu
//     },
//     {
//       path: '**',
//       name: 'PageNotFound',
//       component: PageNotFound
//     }
//   ]
// })

export default new Router({
  // mode: 'history',
  // base: process.env.BASE_URL,
  routes: [
    {
      path: '/',
      name: 'intro',
      component: Intro,
    },
    {
      path: '/home',
      name: 'home',
      component: Home,
    },
    {
      path: '/portfolio',
      name: 'portfolio',
      component: Portfolio,
    },
    {
      path: '/category/:category',
      name: 'category',
      component: Category,
    },
    {
      path: '/brand/:brand',
      name: 'brand',
      component: Brand,
    },
    {
      path: '/brand/:brand/products',
      name: 'brandProducts',
      component: BrandProducts,
    },
    {
      path: '**',
      name: 'notFound',
      component: NotFound,
    },
  ],
});
