<template>

    <div class="navbar">
        <!-- MENU BTN -->
        <div class="menu-btn" @click="showMenu=true">
            <transition name="fade">
              <img v-if="!showMenu" class="menu-icon" src="/img/menu.png" alt="menu">
            </transition>
        </div>
        <!-- END MENU BTN -->
        <!-- LOGO -->
        <div class="logo-container">
            <img src="/img/logo-stg.png" alt="logo" @click="goToIntro">
        </div>
        <!-- END LOGO -->
        <!-- BACK BTN -->
        <div class="back-btn" @click="goBack">
            voltar
        </div>
        <!-- END BACK BTN -->
        <!-- MENU -->
        <transition name="fade">
            <fullmenu v-if="showMenu" @closeMenu="showMenu=false"/>
        </transition>
        <!-- END MENU -->
    </div>

</template>

<script>

import fullmenu from '@/components/Fullmenu.vue';

export default {
    name: 'navbar',
    data: function () {
        return {
            showMenu: false,
        }
    },
    methods: {
        goBack: function () {
            window.history.length > 1
            ? this.$router.go(-1)
            : this.$router.push('/')
        },
        goToIntro: function () {
            this.$router.push({ name:'intro' });
        },
    },
    components: {
        fullmenu,
    },
};
</script>

<style scoped lang="scss">

.navbar {
    color: $beige;
    font-size: 1.5rem;
    font-weight: bold;
    /* LOGO  */
    .logo-container img {
        // max-width: 200px;
        height:60px;
    }
    .menu-btn {
        min-width: 53.13px;
        text-align: left;
        img.menu-icon {
            height: 1.8rem;
        }
    }
}

// Extra small devices (portrait phones, less than 576px)
@media (max-width: 575.98px) {
    .navbar {
        font-size: 1rem;
        .logo-container img {
            // max-width: 150px;
            height: 40px;
        }
        .menu-btn {
            min-width: 35.42px;
            img.menu-icon {
                height: 1rem;
            }
        }
    }
}

// Small devices (landscape phones, 576px and up)
@media (min-width: 568px) and (max-width: 767.98px) and (orientation: landscape), (max-width: 812px) and (max-height: 375px) and (orientation: landscape) {
    .navbar {
        font-size: 1rem;
        .logo-container img {
            // max-width: 150px;
            height: 40px;
        }
        .menu-btn {
            min-width: 35.42px;
            text-align: left;
            img.menu-icon {
                height: 1rem;
            }
        }
    }
}

</style>
