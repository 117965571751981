import { macanudo } from '../brands';

export default [
  {
    product: 'Orange Robusto 1U',
    module: 'Venda à unidade',
    image: '/img/categories/charuto/macanudo/robusto1u/product.png',
    barcode: '5600715274052',
    brand: macanudo,
    filters: {
      intensidade: 'medio',
      vitola: 'robusto',
    },
    properties: [
        { key: "Comprimento", value: "12,7cm" },
        { key: "Diâmetro", value: "2cm" },
        { key: "Folha", value: "Colorado" },
        { key: "Sabor", value: "Forte e picante" },
    ],
  },
  {
    product: 'Orange Gigante 1U',
    module: 'Venda à unidade',
    image: '/img/categories/charuto/macanudo/gigante1u/product.png',
    barcode: '5600715274069',
    brand: macanudo,
    filters: {
      intensidade: 'medio',
      vitola: 'gigante',
    },
    properties: [
        { key: "Comprimento", value: "15,2cm" },
        { key: "Diâmetro", value: "2,4cm" },
        { key: "Folha", value: "Colorado" },
        { key: "Sabor", value: "Forte e picante" },
    ],
  },
  {
    product: 'Orange Marevas 1U',
    module: 'Venda à unidade',
    image: '/img/categories/charuto/macanudo/marevas1u/product.png',
    barcode: '8720400449606',
    brand: macanudo,
    filters: {
      intensidade: 'medio',
      vitola: 'marevas',
    },
    properties: [
        { key: "Comprimento", value: "13cm" },
        { key: "Diâmetro", value: "1,7cm" },
        { key: "Folha", value: "Colorado" },
        { key: "Sabor", value: "Forte e picante" },
    ],
  },
  {
    product: 'Inspirado White Robusto 1U',
    module: 'Venda à unidade',
    image: '/img/categories/charuto/macanudo/whiteRobusto1u/product.png',
    barcode: '8720400484966',
    brand: macanudo,
    filters: {
      intensidade: 'suaveMedio',
      vitola: 'robusto',
    },
    properties: [
        { key: "Comprimento", value: "12,7cm" },
        { key: "Diâmetro", value: "2cm" },
        { key: "Folha", value: "Colorado" },
        { key: "Sabor", value: "Forte e picante" },
    ],
  },
];
