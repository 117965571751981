<template>

    <div class="intro vh-100 w-100 py-3 d-flex flex-column justify-content-between align-items-center position-relative">
        <!-- INVISIBLE -->
        <div class="container button-container flex-grow-1 flex-shrink-0 flex-basis-0">
            <div class="row">
                <div class="col-12">
                    <p class="text-uppercase grey-color mb-0 note">ESTA COMUNICAÇÃO DESTINA-SE, EXCLUSIVAMENTE, A PROFISSIONAIS DO TABACO.</p>
                </div>
            </div>
        </div>
        <!-- END INVISIBLE -->
        <!-- LOGO CONTAINER -->
        <div class="container flex-grow-1 flex-shrink-0 flex-basis-0 d-flex flex-column justify-content-center">
            <div class="row">
                <div class="col-12">
                    <img class="logo show" src="/img/logo-stg-vertical.png" alt="logo">
                </div>
            </div>
        </div>
        <!-- END LOGO CONTAINER -->
        <!-- BUTTON -->
        <div class="container button-container btn-animated flex-grow-1 flex-shrink-0 flex-basis-0 d-flex flex-column justify-content-end">
            <div class="row">
                <div class="col-12">
                    <!-- <router-link tag="a" class="white-color white-border" :class="{ draw: btnIsActive }" to="/home">Descubra o nosso mundo</router-link> -->
                    <a class="white-color white-border bttn" :class="{ draw: btnIsActive }" @click="goToHome">Descubra o nosso mundo</a>
                </div>
            </div>
        </div>
        <!-- END BUTTON -->

        <!-- POPUP -->
        <transition name="fade">
            <div v-if="showPopup" id="popup" class="popup flex-grow-1 d-flex flex-column align-items-center justify-content-center position-absolute transparent-bg">
                <div class="popup-content text-center">
                    <!-- POPUP TXT -->
                    <div class="popup-text font-weight-500 mb-3">
                        Pressione <img src="img/icon-ios-share.png" alt="" class="popup-img"/> e de seguida <img src="img/icon-ios-add-home.png" alt="" class="popup-img"/> <br>para adicionar o <span class="font-weight-bold">'Portfólio Digital'</span><br> ao seu telemóvel.
                    </div>
                    <!-- END POPUP TXT -->
                    <!-- POPUP BTN -->
                    <div class="popup-btn">
                        <!-- <button type="button" name="button" class="btn black-bg yellow-color py-0 px-4" onclick="dismiss()">Ok</button> -->
                        <div class="popup-button py-0 px-4" @click="showPopup = false">Ok</div>
                    </div>
                    <!-- END POPUP BTN -->
                </div>
            </div>
        </transition>
        <!-- END POPUP -->
        <transition name="fade">
            <loading v-if="showLoading" :showloadingicon="controlLoadingIcon" />
        </transition>
    </div>

</template>

<script>

import loading from '@/components/Loading.vue';

export default {
    name: 'intro',
    components: {
      loading,
    },
    data: function () {
        return {
            showPopup: false,
            btnIsActive: false,
            showLoading: false,
            controlLoadingIcon: false,
        }
    },
    methods: {
        isIos: function () {
            if( (navigator.userAgent.match(/iPhone/i)) || (navigator.userAgent.match(/iPod/i)) || (navigator.userAgent.match(/iPad/i)) ) {
                return true;
            }
            return false;
        },
        goToHome: function () {
          this.btnIsActive = true;
          this.showLoading = true;
          setTimeout(() => {
            this.$router.push({ name:'home' });
          }, 350);
        },
    },
    beforeMount() {
        this.isIos();
        // IF IS IOS
        if( this.isIos() && !navigator.standalone ) {
            setTimeout(() => {
                this.showPopup = true;
            }, 2000);
        } else {
            // IF ISN'T IOS
            this.showPopup = false;
        }
    },
};

</script>

<style lang="scss" scoped>

/* SVG CLASSES */
.cls-1 {
    fill:#bec0c2;
    fill-rule:evenodd;
}

.cls-2 {
    fill:#b10836;
}

/* ANIMATION CLASS */
.btn-animated {
    animation: moveInBottom .5s ease-out 1.6s;
    -webkit-animation: moveInBottom .5s ease-out 1.6s;
    animation-fill-mode: backwards;
}

.intro {
    background: url('/img/bg-intro.png') center/cover no-repeat;

    &:before {
        content: '';
        display: block;
        position: absolute;
        top: 0;
        bottom: 0;
        left: 0;
        right: 0;
        background-color: rgba(0,0,0,0.3);
        z-index: 0;
    }
    
    img.logo, svg {
        max-width: 600px;
    }

    /* POPUP */
    .popup {
        left: 0;
        right: 0;
        top: 0;
        bottom: 0;
        z-index: 10000;
        background-color: rgba(0,0,0,0.7);
        // opacity: 0;
        .popup-content {
            position: absolute;
            background-color: rgba(176,8,54,0.8);
            padding: 2rem;
            color: $white;
            .popup-text {
                font-size: 1.5rem;
                line-height: 1.5em;
                img.popup-img {
                    height: 2rem;
                }
            }
            div.popup-button {
                border: 4px solid $white;
                color: $white;
                font-weight: bold;
                font-size: 1.5rem;
                display: inline-block;
            }
        }
    }
}

// Extra small devices (portrait phones, less than 576px)
@media (max-width: 575.98px) {
    .intro {
        /* LOGO  */
        // img.logo, svg {
        //     max-width: 300px;
        // }
        /* NOTe */
        .note {
            font-size: 10px;
        }
        /* POPUP */
        .popup {
            .popup-content {
                padding: 1rem;
                .popup-text {
                    font-size: 1.2rem;
                    img.popup-img {
                        height: 1.5rem;
                    }
                }
                div.popup-button {
                    border: 2px solid $white;
                    font-size: 1.2rem;
                }
            }
        }
    }
}

// Small devices (landscape phones, 576px and up)
@media (min-width: 568px) and (max-width: 767.98px) and (orientation: landscape), (max-width: 812px) and (max-height: 375px) and (orientation: landscape) {
    .intro {
        /* LOGO  */
        // img.logo, svg {
        //     max-width: 300px;
        // }
        /* NOTe */
        .note {
            font-size: 10px;
        }
        /* POPUP */
        .popup {
            .popup-content {
                padding: 1rem;
                .popup-text {
                    font-size: 1.2rem;
                    img.popup-img {
                        height: 1.5rem;
                    }
                }
                div.popup-button {
                    border: 2px solid $white;
                    font-size: 1.2rem;
                }
            }
        }
    }
}

</style>
