import 'bootstrap/dist/css/bootstrap.css'
import 'bootstrap-vue/dist/bootstrap-vue.css'

import Vue from 'vue'
// import BootstrapVue from 'bootstrap-vue';

import App from './App.vue'
import router from './router'


Vue.config.productionTip = false;

// if ('serviceWorker' in navigator) {
//   navigator.serviceWorker
//     .register('/service-worker-cache.js')
//     .then((reg) => {
//       console.log('Service Worker Registered')
//     })
// }

let newWorker;
let refreshing;

if ('serviceWorker' in navigator) {
    // Register the service worker
    navigator.serviceWorker.register('/service-worker-cache.js').then(reg => {
        reg.addEventListener('updatefound', () => {
            console.log(reg.installing);
            // An updated service worker has appeared in reg.installing!
            newWorker = reg.installing;

            newWorker.addEventListener('statechange', () => {

                // Has service worker state changed?
                switch (newWorker.state) {
                    case 'installed':

                    // There is a new service worker available, show the notification
                    if (navigator.serviceWorker.controller) {
                        // let notification = document.getElementById('notification ');
                        // notification .className = 'show';
                    }

                    break;
                }
            });
        });
    });

    // The event listener that is fired when the service worker updates
    // Here we reload the page
    navigator.serviceWorker.addEventListener('controllerchange', function () {
        if (refreshing) return;

        window.location.reload();

        refreshing = true;
    });


}


new Vue({
  el: '#app',
  router,
  components: { App },
  template: '<App/>'
});
