<template>

    <div class="not-found vh-100 w-100 py-3 d-flex flex-column justify-content-between align-items-center position-relative">
        <!-- INVISIBLE -->
        <div class="container invisible button-container btn-animated">
            <div class="row">
                <div class="col-12">
                    <router-link tag="a" class="white-color white-border" to="/home">Voltar ao nosso mundo</router-link>
                </div>
            </div>
        </div>
        <!-- END INVISIBLE -->
        <!-- LOGO CONTAINER -->
        <div class="container">
            <div class="row">
                <div class="col-12 mb-5">
                    <img class="logo" src="/img/logo-stg-vertical.png" alt="logo">
                </div>
                <div class="col-12 white-color">
                    <h5 class="font-weight-bold">Página não encontrada.</h5>
                </div>
            </div>
        </div>

        <!-- END LOGO CONTAINER -->
        <!-- BUTTON -->
        <div class="container button-container btn-animated">
            <div class="row">
                <div class="col-12">
                    <router-link tag="a" class="white-color white-border" to="/home">Voltar ao nosso mundo</router-link>
                </div>
            </div>
        </div>
        <!-- END BUTTON -->
    </div>

</template>

<script>

export default {
    name: 'intro',
};

</script>

<style lang="scss" scoped>

/* SVG CLASSES */
.cls-1 {
    fill:#bec0c2;
    fill-rule:evenodd;
}

.cls-2 {
    fill:#b10836;
}

/* ANIMATION CLASS */
.btn-animated {
    animation: moveInBottom .5s ease-out 0.3s;
    -webkit-animation: moveInBottom .5s ease-out 0.3s;
    animation-fill-mode: backwards;
}

.not-found {
    img.logo, svg {
        max-width: 400px;
    }

    h5 {
        font-size: 1.8rem;
        text-transform: none !important;
    }
}

// Extra small devices (portrait phones, less than 576px)
@media (max-width: 575.98px) {
    .not-found {
        /* LOGO  */
        img.logo, svg {
            max-width: 250px;
        }
        h5 {
            font-size: 1.2rem;
        }
    }
}

// Small devices (landscape phones, 576px and up)
@media (min-width: 568px) and (max-width: 767.98px) and (orientation: landscape), (max-width: 812px) and (max-height: 375px) and (orientation: landscape) {
    .not-found {
        /* LOGO  */
        img.logo, svg {
            max-width: 250px;
        }
        h5 {
            font-size: 1.2rem;
        }
    }
}

</style>
