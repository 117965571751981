import { mercator } from '../brands';

export default [
  {
    product: 'Fiesta 10',
    module: 'Módulo: 10 Unidades',
    image: '/img/categories/cigarrilhas/mercator/fiesta10/product.png',
    barcode: '5414842355115',
    brand: mercator,
    filters: {
      tamanho: 'regular',
      sabor: 'original',
      filtro: 'semFiltro',
    },
    properties: [],
  },
];
