<template>

    <div class="loading d-flex justify-content-center align-items-center">
        <div v-if="showloadingicon" class="icon"></div>
    </div>

</template>

<script>

export default {
    name: 'loading',
    props: ['showloadingicon'],
};

</script>

<style scoped lang="scss">

.loading {
    position: fixed;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    background-color: rgba(0,0,0,0.1);
    .icon {
        background-color: $red;
        -webkit-mask-image: url('/img/spinner-solid.svg');
        mask-image: url('/img/spinner-solid.svg');
        height: 2rem;
        width: 2rem;
        animation: spinner 2s linear infinite;
    }
}

</style>
