import { crossRoad } from '../brands';

export default [
  {
    product: 'Original 30g',
    module: 'Módulo: 5 Onças',
    image: '/img/categories/enrolar/crossRoad/original30/product.png',
    barcode: '5710840155611',
    brand: crossRoad,
    filters: {
      sabor: 'original',
    },
    properties: [],
  },
  {
    product: 'Gold 30g',
    module: 'Módulo: 5 Onças',
    image: '/img/categories/enrolar/crossRoad/gold30/product.png',
    barcode: '5710840155642',
    brand: crossRoad,
    filters: {
      sabor: 'suave',
    },
    properties: [],
  },
  {
    product: 'Virginia 30g',
    module: 'Módulo: 5 Onças',
    image: '/img/categories/enrolar/crossRoad/virginia30/product.png',
    barcode: '5710840169304',
    brand: crossRoad,
    filters: {
      sabor: 'virginia',
    },
    properties: [],
  },
];
