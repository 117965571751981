import { clan } from '../brands';

export default [
  {
    product: 'Original 35g',
    module: 'Módulo: 5 Onças',
    image: '/img/categories/cachimbo/clan/original35/product.png',
    barcode: '5710840140495',
    brand: clan,
    filters: {
      corpo: '3',
      sabor: '4',
    },
    properties: [
        { key: "Nota de aroma", value: "3" },
    ],
  },
];
