import laPaz from "./laPaz";
import mercator from "./mercator";
import henriWintermans from "./henriWintermans";
import breakX from "./breakX";
// import note from "./note";
import borkumRiff from "./borkumRiff";
import skandinavik from "./skandinavik";
import alsboBlack from "./alsboBlack";
import sail from "./sail";
import clan from "./clan";
import hollandHouse from "./hollandHouse";
import macanudo from "./macanudo";
import cao from "./cao";
import donTomas from "./donTomas";
import reposado from "./reposado";
import florDelSol from "./florDelSol";
import laEstrella from "./laEstrella";
import crossRoad from "./crossRoad";
import signature from "./signature";
import signaturePlc from "./signaturePlc";
import panter from "./panter";
// import meharis from "./meharis";

export default [
  ...signature,
  // ...signaturePlc,
  ...laPaz,
  ...mercator,
  // ...panter,
  // ...meharis,
  // ...henriWintermans,
  ...breakX,
  // ...note,
  ...borkumRiff,
  ...skandinavik,
  ...alsboBlack,
  ...sail,
  ...clan,
  ...hollandHouse,
  ...macanudo,
  ...cao,
  ...donTomas,
  // ...laEstrella,
  ...reposado,
  ...florDelSol,
  ...crossRoad,
];
