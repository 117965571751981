import { hollandHouse } from '../brands';

export default [
  {
    product: 'Original 35g',
    module: 'Módulo: 5 Onças',
    image: '/img/categories/cachimbo/hollandHouse/original35/product.png',
    barcode: '5710840140525',
    brand: hollandHouse,
    filters: {
      corpo: '3',
      sabor: '3',
    },
    properties: [
        { key: "Nota de aroma", value: "3" },
    ],
  },
];
