<template>
    <img
        ref="barcode"
        class="barcode d-none d-md-inline-block iphone-x-hide"
    />
</template>

<script>
import JsBarcode from 'jsbarcode';

export default {
    name: 'Barcode',
    props: ['value'],
    data: () => ({
        opts: {
            format: "ean13",
            width: 2,
            height: 80,
            margin: 16,
            fontSize: 20,
            font: "sans-serif",
        },
    }),
    mounted() {
        JsBarcode(this.$refs.barcode, this.value, this.opts);
    },
};

</script>

<style scoped lang="scss">

img.barcode {
    width: 100%;
    max-width: 123px;
    margin-top: 0.75rem;
}

</style>
