<template>

    <div ref="popup" class="popup position-fixed d-flex align-items-center justify-content-center">
        <div class="popup-content d-none d-md-flex flex-column p-4 bounceInDownPopup iphone-x-hide">
            <!-- CLOSE -->
            <div class="close-container text-right">
                <img @click="$emit('close')" class="menu-icon" src="/img/close.png" alt="fechar">
            </div>
            <!-- END CLOSE -->
            <!-- PRODUCT -->
            <div class="product-content row d-flex flex-row flex-grow-1 align-items-center">
                <!-- PRODUCT IMAGE -->
                <div class="col-12 col-md-7">
                    <div class="product-image">
                        <img :src="product.image" :alt="product.product">
                    </div>
                </div>
                <!-- END PRODUCT IMAGE -->
                <!-- PRODUCT TEXT -->
                <div class="col-12 col-md-5">
                    <div class="product-information text-left">
                        <p class="mb-2 mt-n3">
                            <strong>{{ product.brand.name }}</strong>
                        </p>
                        <p class="mb-2">
                            {{ product.product }}
                        </p>
                        <p class="mb-2">
                            {{ product.module }}
                        </p>

                        <app-barcode :value="product.barcode" />
                        
                    </div>
                </div>
                <!-- PRODUCT TEXT -->
            </div>
            <!-- END PRODUCT -->
            <!-- PRODUCT FILTERS -->
            <div class="product-filters d-flex flex-column justify-content-center align-items-center moveInBottom" v-if="product.brand.category.slug != 'enrolar'">
                <div class="">
                    <img class="category-img mb-3" :src="'/img'+product.brand.category.image">
                </div>
                <div class="d-flex flex-row flex-grow-1 flex-wrap justify-content-center">
                    <div class="ml-2" v-for="(filter, idx) in getProductFilterDetails(product)" :key="'cat-'+idx">
                        <span v-if="product.brand.category.slug != 'cigarrilhas'">{{ filter.category.name }}: </span><strong>{{ filter.item.name }}</strong>{{ (idx+1) <= getProductFilterDetails(product).length ? ', ' : '' }}
                    </div>
                    <div class="ml-2" v-for="(property, idx) in product.properties" :key="'idx2'+idx" v-if="product.properties">
                        {{ property.key }}: <strong>{{ property.value }}</strong>{{ (idx+1) < product.properties.length ? ', ' : '' }}
                    </div>
                </div>
            </div>
            <!-- END PRODUCT FILTERS -->
        </div>

        <!-- MOBILE -->
        <div class="popup-content d-flex d-md-none flex-column p-2 bounceInDownPopup iphone-x-show">
            <!-- CLOSE -->
            <div class="close-container text-right">
                <img @click="$emit('close')" class="menu-icon" src="/img/close.png" alt="fechar">
            </div>
            <!-- END CLOSE -->
            <!-- PRODUCT -->
            <div class="product-content row d-flex flex-row flex-grow-1 flex-shrink-0 align-items-center">
                <!-- PRODUCT IMAGE -->
                <div class="col-12">
                    <div class="product-image">
                        <img :src="product.image" :alt="product.product">
                    </div>
                </div>
                <!-- END PRODUCT IMAGE -->
                <!-- PRODUCT TEXT -->
                <div class="col-12 col-lg-5">
                    <div class="product-information">
                        <p class="mb-0">
                            <strong>{{ product.brand.name }}</strong> {{ product.product }}
                        </p>
                        <p class="mb-0">
                            {{ product.module }}
                        </p>
                    </div>
                </div>
                <!-- PRODUCT TEXT -->
            </div>
            <!-- END PRODUCT -->
            <!-- PRODUCT FILTERS -->
            <div class="product-filters d-flex flex-column justify-content-center align-items-center flex-shrink-0 moveInBottom" v-if="product.brand.category.slug != 'enrolar'">
                <img class="category-img" :src="'/img'+product.brand.category.image">
                <div class="d-flex flex-row flex-grow-1 flex-wrap justify-content-center">
                    <div class="ml-2" v-for="(filter, idx) in getProductFilterDetails(product)" :key="'idx3'+idx">
                        <span v-if="product.brand.category.slug != 'cigarrilhas'">{{ filter.category.name }}: </span><strong>{{ filter.item.name }}</strong> {{ (idx+1) < getProductFilterDetails(product).length ? ', ' : '' }}
                    </div>
                    <div class="ml-2" v-for="(property, idx) in product.properties" :key="'idx4'+idx" v-if="product.properties">
                        {{ property.key }}: <strong>{{ property.value }}</strong>{{ (idx+1) < product.properties.length ? ', ' : '' }}
                    </div>
                </div>
            </div>
            <!-- END PRODUCT FILTERS -->
        </div>
        <!-- END MOBILE -->
    </div>

</template>

<script>
import Barcode from '@/components/Barcode';
import { getProductFilterDetails } from '@/providers/portfolio';

export default {
    name: 'product-popup',
    props: ['product'],
    components: {
        "app-barcode": Barcode,
    },
    methods: {
        getProductFilterDetails: getProductFilterDetails,
    },
    mounted() {
        this.$refs.popup.addEventListener('touchmove', function(e) {
            e.preventDefault();
            e.stopPropagation();
        });
    },
};

</script>

<style scoped lang="scss">

/* ANIMATION CLASS */
.moveInBottom {
    animation: moveInBottom .5s ease-out 0.5s;
    animation-fill-mode: backwards;
}

.popup {
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    z-index: 100;
    .popup-content {
        background-color: rgba(0,0,0,0.9);
        color: $white;
        height: 100%;
        max-height: 600px;
        width: 100%;
        max-width: 900px;
        /* CLOSE */
        .close-container {
            img.menu-icon {
                height: 1.8rem;
            }
        }
        /* PRODUCT-CONTENT */
        .product-content {
            /* PRODUCT-IMAGE */
            .product-image {
                img {
                    width: 100%;
                    // max-height: 350px;
                    max-height: 410px;
                    object-fit: contain;
                }
            }
            /* PRODUCT-INFORMATION */
            .product-information {
                font-size: 2.5rem;
            }
        }
        /* PRODUCT-FILTERS */
        .product-filters {
            color: $beige;
            font-size: 1.2rem;
            img.category-img {
                max-height: 60px;
            }
        }
    }
}

// Extra small devices (portrait phones, less than 576px)
@media (max-width: 575.98px) {
    .popup {
        .popup-content {
            height: 100%;
            // max-height: 400px;
            max-height: 430px;
            width: 100%;
            max-width: 275px;
            /* CLOSE */
            .close-container {
                justify-content: center;
                img.menu-icon {
                    height: 1rem;
                }
            }
            .product-content {
                /* PRODUCT-IMAGE */
                .product-image {
                    img {
                        // max-height: 150px;
                        max-height: 185px;
                    }
                }
                /* PRODUCT-INFORMATION */
                .product-information {
                    font-size: 1.2rem;
                }
            }
            /* PRODUCT-FILTERS */
            .product-filters {
                font-size: 1rem;
                img.category-img {
                    max-height: 50px;
                    margin-bottom: 1rem;
                }
            }
        }
    }
}

// Medium devices (tablets, 768px and up)
@media (min-width: 768px) and (max-width: 991.98px) and (orientation: portrait) {
    .popup {
        .popup-content {
            max-width: 700px;
        }
    }
}

// Small devices (landscape phones, 576px and up)
@media (min-width: 568px) and (max-width: 767.98px) and (orientation: landscape), (max-width: 812px) and (max-height: 375px) and (orientation: landscape) {
    .popup {
        .popup-content {
            height: 100%;
            // max-height: 400px;
            max-height: 430px;
            width: 100%;
            max-width: 350px;
            overflow-y: scroll;
            /* CLOSE */
            .close-container {
                justify-content: center;
                img.menu-icon {
                    height: 1rem;
                }
            }
            .product-content {
                /* PRODUCT-IMAGE */
                .product-image {
                    img {
                        // max-height: 150px;
                        max-height: 185px;
                    }
                }
                /* PRODUCT-INFORMATION */
                .product-information {
                    font-size: 1.2rem;

                }
            }
            /* PRODUCT-FILTERS */
            .product-filters {
                font-size: 1rem;
                img.category-img {
                    max-height: 50px;
                    margin-bottom: 1rem;
                }
            }
        }
    }
}

@media (min-width: 568px) and (max-width: 767.98px) and (orientation: landscape), (max-width: 812px) and (max-height: 375px) and (orientation: landscape) {

  .iphone-x-hide {
    display: none !important;
  }

  .iphone-x-show {
    display: flex !important;
  }

}

</style>
