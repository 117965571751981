import {
  cigarrilhas as filterCigarrilhas,
  cachimbo as filterCachimbo,
  charuto as filterCharuto,
  enrolar as filterEnrolar,
} from './filters';

const cigarrilhas = {
  slug: 'cigarrilhas',
  name: 'Cigarrilhas',
  description: 'Um sabor natural e verdadeiro, envolvido em folha de tabaco.',
  image: '/categories/cigarrilhas/category-img.png',
  background: '/categories/cigarrilhas/bg.jpg',
  filters: filterCigarrilhas,
};

const cachimbo = {
  slug: 'cachimbo',
  name: 'Cachimbo',
  description: 'Obra prima de elegância e sofisticação.',
  image: '/categories/cachimbo/category-img.png',
  background: '/categories/cachimbo/bg.jpg',
  filters: filterCachimbo,
};

const charuto = {
  slug: 'charuto',
  name: 'Charuto',
  description: 'Para celebrar cada dia de forma singular e intensa.',
  image: '/categories/charuto/category-img.png',
  background: '/categories/charuto/bg.jpg',
  filters: filterCharuto,
};

const enrolar = {
  slug: 'enrolar',
  name: 'Tabaco de Enrolar',
  description: 'Para um tabaco mais natural e genuíno.',
  image: '/categories/enrolar/category-img.png',
  background: '/categories/enrolar/bg.jpg',
  filters: filterEnrolar,
};

const list = [
  cigarrilhas,
  cachimbo,
  charuto,
  enrolar,
];

export {
  cigarrilhas,
  cachimbo,
  charuto,
  enrolar,
  list,
};
