import { sail } from '../brands';

export default [
  {
    product: 'Black 35g',
    module: 'Módulo: 5 Onças',
    image: '/img/categories/cachimbo/sail/black35/product.png',
    barcode: '5710840140556',
    brand: sail,
    filters: {
      corpo: '2',
      sabor: '4',
    },
    properties: [
        { key: "Nota de aroma", value: "3" },
    ],
  },
];
