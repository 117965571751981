/* eslint-disable */

import _ from 'lodash';
import { list as categories } from './categories';
import { list as brands } from './brands';
import products from './products';

/**
 * Gets all the categories
 *
 * @return array
 */
function getCategories() {
  return categories;
}

/**
 * Gets the category with a specific slug
 *
 * @param string categorySlug
 * @return object|null
 */
function getCategory(categorySlug) {
  let resp = null;
  const idx = _.findIndex(categories, ['slug', categorySlug]);
  if (idx > -1) {
    resp = categories[idx];
  } else {
    throw new Error(`The category with the slug '${categorySlug}' does NOT exist.`);
  }
  return resp;
}

/**
 * Gets all the brands
 *
 * @return array
 */
function getBrands() {
  return brands;
}

/**
 * Gets the brand with a specific slug
 *
 * @param string brandSlug
 * @return object|null
 */
function getBrand(brandSlug) {
  let resp = null;
  const idx = _.findIndex(brands, ['slug', brandSlug]);
  if (idx > -1) {
    resp = brands[idx];
  } else {
    throw new Error(`The brand with the slug '${brandSlug}' does NOT exist.`);
  }
  return resp;
}

/**
 * Get brands by category slug
 *
 * @param string categorySlug
 * @return array
 */
function getBrandsByCategory(categorySlug) {
  let resp = [];
  if (_.findIndex(categories, ['slug', categorySlug]) > -1) {
    resp = _.filter(brands, obj => obj.category.slug === categorySlug);
  } else {
    throw new Error(`The category with the slug '${categorySlug}' does NOT exist.`);
  }
  return resp;
}

/**
 * Gets all the products
 *
 * @return array
 */
function getProducts() {
  return products;
}

/**
 * Gets all the products from a category with a specific slug
 *
 * @param string categorySlug
 * @return array|null
 */
function getProductsByCategory(categorySlug) {
  let resp = null;
  if (_.findIndex(categories, ['slug', categorySlug]) > -1) {
    resp = _.filter(products, obj => obj.brand.category.slug === categorySlug);
  } else {
    throw new Error(`The category with the slug '${categorySlug}' does NOT exist.`);
  }
  return resp;
}

/**
 * Gets all the products from a brand with a specific slug
 *
 * @param string brandSlug
 * @return array|null
 */
function getProductsByBrand(brandSlug) {
  let resp = null;
  if (_.findIndex(brands, ['slug', brandSlug]) > -1) {
    resp = _.filter(products, obj => obj.brand.slug === brandSlug);
  } else {
    throw new Error(`The brand with the slug '${brandSlug}' does NOT exist.`);
  }
  return resp;
}

/**
 * Gets all the products from a category with a specific slug and filters
 *
 * @param string brandSlug
 * @param object filterObject
 * @return array
 */
function getProductsFiltered(categorySlug, filterObject) {
  const filtrs = getCategory(categorySlug).filters;
  let prods = getProductsByCategory(categorySlug);
  _.forEach(filterObject, (value, key) => {
    const filterCategoryIdx = _.findIndex(filtrs, ['category.slug', key]);
    const filterCategoryExists = filterCategoryIdx > -1;
    const filterItemExists = !filterCategoryExists ? false : _.findIndex(filtrs[filterCategoryIdx].items, ['slug', value]) > -1;

    if (!filterCategoryExists) {
      throw new Error(`The filter category with the slug '${key}' does NOT exist.`);
    } else if (!filterItemExists && value) {
      throw new Error(`The filter item with the slug '${value}' does NOT exist.`);
    } else if (value) {
      prods = _.filter(prods, obj => obj.filters[key] === value);
    }
  });
  return prods;
}

/**
 * Gets the details of a specific product filters
 *
 * @param object productObj
 * @return array
 */
function getProductFilterDetails(productObj) {
  const categoyFiltrs = productObj.brand.category.filters;
  let resp = [];
  _.forEach(productObj.filters, (value, key) => {
    const currFilter = _.filter(categoyFiltrs, obj => obj.category.slug === key)[0];
    const currFilterItem = _.filter(currFilter.items, obj => obj.slug === value)[0];
    resp.push({
      category: currFilter.category,
      item: currFilterItem,
    });
  });
  return resp;
}

export {
  getCategories,
  getCategory,
  getBrandsByCategory,
  getBrands,
  getBrand,
  getProducts,
  getProductsByCategory,
  getProductsByBrand,
  getProductsFiltered,
  getProductFilterDetails,
};
