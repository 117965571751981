<template>
  <div
    class="category vh-100 vw-100 pb-3 pb-md-3 d-flex flex-column flex-grow-1"
    :style="{ backgroundImage: 'url(/img' + category.background + ')' }"
  >
    <navbar />
    <div class="category-content d-flex flex-column flex-grow-1">
      <h1>{{ category.name }}</h1>
      <h4 class="mb-3">{{ category.description }}</h4>
      <!-- FILTERS -->
      <div
        class="
          filters-container
          d-none d-md-flex
          flex-row
          mb-3
          position-relative
          justify-content-center
          iphone-x-hide
        "
      >
        <div class="filter-content-container d-flex justify-content-around">
          <div
            class="filter-content"
            v-for="(filter, filter_key) in filtersUi"
            :key="filter_key"
          >
            <div class="filter-button" @click="filterClickHandler(filter)">
              <div
                class="filter-button-content"
                id="filter-button-content"
                v-if="filter.activeItem == null"
              >
                {{ filter.data.category.name }}
                <img
                  class="dropdown-icon"
                  :class="{ active: filter.expanded }"
                  src="/img/white-arrow.png"
                  alt="Seta"
                />
              </div>
              <div
                class="item-active"
                v-else
                :class="{ 'dropdown-active': filter.expanded }"
              >
                <span v-if="!filter.expanded">
                  {{ getActiveItemName(filter) }}
                </span>
                <span v-else> {{ filter.data.category.name }} </span>
                <img
                  class="dropdown-icon"
                  :class="{ active: filter.expanded }"
                  src="/img/white-arrow.png"
                  alt="Seta"
                />
              </div>
            </div>
            <!-- <div class="filter-item-container" :class="{clicked: filter.expanded }" v-on-clickaway="dismissDropdownFilterClicked"> -->
            <div
              class="filter-item-container"
              :class="{ clicked: filter.expanded }"
            >
              <li
                v-for="(filterItem, filterItem_idx) in filter.data.items"
                :key="filterItem_idx"
                class="filter-item"
                :class="{ active: filter.activeItem == filterItem.slug }"
                @click="filterItemClickHandler(filter, filterItem)"
              >
                {{ filterItem.name }}
              </li>
            </div>
          </div>
        </div>
        <div
          class="clear-filters position-absolute"
          @click="clearSelectedFilters"
          v-if="count != checkSelectedFilterSize()"
        >
          Limpar filtro
        </div>
      </div>
      <!-- END FILTERS -->
      <!-- FILTERS MOBILE -->
      <div
        class="
          filters-container
          d-flex d-md-none
          flex-column
          justify-content-center
          align-items-center
          position-relative
          flex-shrink-0
          iphone-x-show
        "
      >
        <div
          class="filter-btn py-1 px-4 font-weight-bold"
          :class="{
            'filter-btn-active':
              showFilters || count != checkSelectedFilterSize(),
          }"
          @click="toggleShowFilters"
          id="filterBtn"
        >
          filtro
        </div>
        <transition name="fade">
          <!-- <div v-if="showFilters" v-on-clickaway="dismissDropdown" class="filter-container px-3"> -->
          <div
            v-if="showFilters"
            v-click-outside="onClickOutsideMobile"
            class="filter-container px-3"
          >
            <div
              class="filter-content mb-1"
              v-for="(filter, filter_key) in filtersUi"
              :key="filter_key"
            >
              <div class="filter-button" @click="filterClickHandler(filter)">
                <div v-if="filter.activeItem == null">
                  {{ filter.data.category.name }}
                  <img
                    class="dropdown-icon"
                    :class="{ active: filter.expanded }"
                    src="/img/arrow.png"
                    alt="Seta"
                  />
                </div>
                <div class="item-active" v-else>
                  {{ getActiveItemName(filter) }}
                </div>
              </div>
              <div
                class="filter-item-container"
                :class="{ clicked: filter.expanded }"
              >
                <li
                  v-for="(filterItem, filterItem_idx) in filter.data.items"
                  :key="filterItem_idx"
                  class="filter-item"
                  :class="{ active: filter.activeItem == filterItem.slug }"
                  @click="filterItemClickHandler(filter, filterItem)"
                >
                  {{ filterItem.name }}
                </li>
              </div>
            </div>
            <transition name="fade">
              <div
                class="clear-filters"
                @click="clearSelectedFilters"
                v-if="count != checkSelectedFilterSize()"
              >
                Limpar filtro
              </div>
            </transition>
          </div>
        </transition>
      </div>
      <!-- END FILTERS MOBILE -->
      <!-- CAROUSEL -->
      <div
        class="
          carousel-holder
          w-100
          d-none d-md-flex
          flex-column flex-grow-1
          justify-content-center
          iphone-x-hide
        "
      >
        <carousel
          :perPage="1"
          paginationActiveColor="rgba(255,255,255,1)"
          paginationColor="rgba(255,255,255,.3)"
          :paginationPadding="6"
        >
          <slide v-for="(page, page_idx) in pagedProducts" :key="page_idx">
            <div class="product-holder">
              <div
                class="product-item mt-3"
                v-for="(product, product_idx) in page"
                :style="{ width: calculateWidth(page) }"
                :key="product_idx"
                @click="clickedProductHandler(product)"
              >
                <img :src="product.image" />
                <div class="product-name">
                  {{ product.brand.name }}<br />
                  <strong>{{ product.product }}</strong>
                </div>
              </div>
            </div>
          </slide>
        </carousel>

        <div v-if="productsTotal == 0" class="d-flex justify-content-center">
          <h4 class="white-color">
            Não existem produtos disponíveis com os filtros seleccionados.
          </h4>
        </div>
      </div>
      <!-- CAROUSEL -->
      <!-- CAROUSEL MOBILE -->
      <div
        class="
          carousel-holder
          w-100
          d-flex
          flex-column
          align-items-center
          d-md-none
          iphone-x-show
        "
      >
        <div
          class="product-item mt-4"
          v-for="(product, product_idx) in products"
          :key="product_idx"
        >
          <img :src="product.image" @click="clickedProductHandler(product)" />
          <div class="product-name">
            {{ product.brand.name }}<br />
            <strong>{{ product.product }}</strong>
          </div>
        </div>

        <div class="container mt-3">
          <div class="row">
            <div class="col-12 position-static">
              <div
                v-if="productsTotal == 0"
                class="d-flex justify-content-center"
              >
                <h4 class="white-color text-center">
                  Não existem produtos disponíveis com os filtros seleccionados.
                </h4>
              </div>
            </div>
          </div>
        </div>
      </div>

      <!-- END CAROUSEL MOBILE -->
      <!-- POPUP -->
      <transition name="fade">
        <product-popup
          :product="activeProduct"
          v-if="showPopup"
          @close="showPopup = false"
        />
      </transition>
      <!-- END POPUP -->
    </div>
  </div>
</template>

<script>
/* eslint-disable */
import { Carousel, Slide } from "vue-carousel";
import {
  getBrand,
  getProductsByCategory,
  getCategory,
  getProductsFiltered,
} from "@/providers/portfolio";
import navbar from "@/components/Navbar.vue";
import productPopup from "@/components/ProductPopup.vue";
import _ from "lodash";

import vClickOutside from "v-click-outside";

const PRODUCTS_PER_PAGE = 10;

export default {
  name: "category",
  components: {
    carousel: Carousel,
    slide: Slide,
    navbar,
    productPopup,
  },
  directives: {
    clickOutside: vClickOutside.directive,
  },
  data: function () {
    return {
      category: {},
      products: [],
      filtersUi: {},
      selectedFilter: {},
      showPopup: false,
      activeProduct: {},
      showFilters: false,
      count: 0,
    };
  },
  computed: {
    productsTotal: function () {
      return this.products.length;
    },
    pagedProducts: function () {
      /**
       * Alteração efetuada em 4/06/2020
       * Para a categoria slug==cigarrilhas os slides
       * são apresentados por marca e não corrido
       */
      /**
       * Se categoria cigarilhas e os 3 tipos de filtros estiverem a NULL
       * Construimos os slides por marca.
       */
      if (this.category.slug == "cigarrilhas" && this.count == 3) {
        const prods = _.groupBy(this.products, (prod) => {
          const brand = getBrand(prod.brand.slug);
          return brand.slideGroup;
        });

        let pages = [];

        _.forEach(prods, (item) => {
          if (item.length <= PRODUCTS_PER_PAGE) {
            pages.push(item);
          } else {
            const itemsPerPageArr = item[0].brand.itemsPerPage || null;
            if (itemsPerPageArr) {
              let itemClone = _.cloneDeep(item);
              _.forEach(itemsPerPageArr, (itemsPerPage) => {
                const temp = itemClone.splice(itemsPerPage);
                pages.push(itemClone);
                itemClone = temp;
              });
            } else {
              const chunk = _.chunk(item, PRODUCTS_PER_PAGE);
              pages.push(...chunk);
            }
          }
        });
        return pages;
      }

      if (this.category.slug == "charuto") {
        return _.chunk(this.products, 12);
      }

      return _.chunk(this.products, PRODUCTS_PER_PAGE);
    },
  },
  methods: {
    calculateWidth(page) {
      const numberPerRow =
        page.length > 3
          ? Math.round(100 / Math.ceil(page.length / 2))
          : Math.round(100 / page.length);

      // console.log("numberPerRow", numberPerRow);

      if (numberPerRow == 17) {
        return "16%";
      }

      if (numberPerRow == 20) {
        return "20%";
      }

      if (numberPerRow == 25) {
        return "25%";
      }

      if (numberPerRow == 33) {
        return "28%";
      }

      return "19.98%";
    },
    getActiveItemName: function (filter) {
      const filterItem = _.find(
        filter.data.items,
        (obj) => obj.slug === filter.activeItem
      );
      return filterItem.name;
    },
    filterClickHandler: function (filter) {
      _.forEach(this.filtersUi, (value, key) => {
        if (key == filter.data.category.slug) {
          value.expanded = !value.expanded;
        } else {
          value.expanded = false;
        }
      });
      this.$forceUpdate();
    },
    filterItemClickHandler: function (filter, filterItem) {
      filter.activeItem = filterItem.slug;
      filter.expanded = false;
      this.selectedFilter[filter.data.category.slug] = filterItem.slug;
      this.products = getProductsFiltered(
        this.category.slug,
        this.selectedFilter
      );
      this.$forceUpdate();
      this.count = this.checkFilters();
    },
    clearSelectedFilters: function () {
      _.forEach(this.filtersUi, (value, key) => {
        value.expanded = false;
        value.activeItem = null;
        this.selectedFilter[key] = null;
      });
      this.products = getProductsByCategory(this.category.slug);
      this.$forceUpdate();
      this.count = this.checkFilters();
    },
    clickedProductHandler: function (product) {
      this.activeProduct = product;
      this.showPopup = true;
    },
    toggleShowFilters: function () {
      if (!this.showFilters) {
        this.showFilters = true;
      } else {
        this.showFilters = false;
      }
      this.count = this.checkFilters();
    },
    checkFilters: function () {
      this.count = 0;
      _.forEach(this.filtersUi, (value, key) => {
        if (this.selectedFilter[key] == null) {
          this.count++;
        }
      });
      return this.count;
    },
    checkSelectedFilterSize: function () {
      return _.size(this.selectedFilter);
    },
    // onClickOutside (event) {
    //   console.log("oi");
    //   if(event.target.id !== 'filter-button-content') {
    //     _.forEach(this.filtersUi, (value, key) => {
    //         value.expanded = false;
    //     });
    //   }
    // },
    onClickOutsideMobile(event) {
      if (event.target.id !== "filterBtn") {
        this.showFilters = false;
      }
    },
    // dismissDropdownFilterClicked: function () {
    //     console.log("aqui");
    //     _.forEach(this.filtersUi, (value, key) => {
    //         value.expanded = false;
    //     });
    // },
  },
  beforeRouteUpdate(to, from, next) {
    this.category = getCategory(to.params.category);
    this.products = getProductsByCategory(this.category.slug);

    this.selectedFilter = {};
    this.filtersUi = {};

    _.forEach(
      this.category.filters,
      function (value) {
        this.selectedFilter[value.category.slug] = null;
        this.filtersUi[value.category.slug] = {
          data: value,
          activeItem: null,
          expanded: false,
        };
      }.bind(this)
    );

    this.checkFilters();

    next(); //make sure you always call next()
  },
  beforeMount() {
    this.category = getCategory(this.$route.params.category);

    this.products = getProductsByCategory(this.category.slug);

    _.forEach(this.category.filters, (value) => {
      this.selectedFilter[value.category.slug] = null;
      this.filtersUi[value.category.slug] = {
        data: value,
        activeItem: null,
        expanded: false,
      };
    });
    this.checkFilters();
  },
};
</script>

<style lang="scss" scoped>
.category {
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
  color: #fff;
}

.category h1 {
  color: $red;
  font-weight: bold;
  font-size: 3.5rem;
  margin-bottom: 1.5rem;
}

.category h4 {
  font-weight: bold;
  font-size: 1.3rem;
}

.filters-content-container {
  max-width: 700px;
}

.filter-content {
  position: relative;
  border: 2px solid $white;
  margin-right: 2rem;
}

.filter-content:last-child {
  margin-right: 0rem;
}

.filter-button,
li.filter-item {
  font-weight: bold;
  font-size: 1.4rem;
  width: 200px;

  .item-active {
    color: $red;
    background-color: $white;
    // padding-top: 0.2rem;
    line-height: 2.5rem;
    &.dropdown-active {
      color: $white;
      background-color: transparent;
    }
  }
}

.filter-button-content {
  line-height: 2.5rem;
}

.filter-button img.dropdown-icon {
  position: absolute;
  right: 0.5rem;
  height: 1rem;
  top: 50%;
  transform: translateY(-50%);
  transition: transform 0.3s;
  &.active {
    transform: translateY(-50%) rotate(-90deg);
  }
}

.filter-item-container {
  position: absolute;
  z-index: 10;
  max-height: 0px;
  overflow: hidden;
  // border-bottom: 2px solid $white;
}

.filter-item-container.clicked {
  max-height: 300px;
  border: 2px solid $white;
  left: -2px;
  transition: max-height 0.5s;
}

li.filter-item {
  list-style-type: none;
  color: $red;
  background-color: $grey;
  border-bottom: 2px solid $white;
}

li.filter-item.active {
  background-color: $white;
}

li.filter-item:last-child {
  border-bottom: none;
}

.clear-filters {
  text-decoration: underline;
  margin-top: 0.3rem;
  font-size: 1.2rem;
  position: absolute;
  right: 2rem;
}

.carousel-holder {
  text-align: left;
  .product-holder {
    display: flex;
    justify-content: center;
    flex-wrap: wrap;
  }
  .product-item {
    text-align: center;
    box-sizing: border-box;
    display: inline-block;
    width: 19.98%;
    margin: 0;
    vertical-align: top;

    img {
      max-height: 140px;
      margin-bottom: 0.5em;
    }

    .product-name {
      font-size: 1.2rem;
      line-height: 1.1em;
    }
  }

  .VueCarousel-pagination {
    .VueCarousel-dot-container {
      margin-top: 0px !important;
      .VueCarousel-dot {
        margin-top: 0px !important;
      }
    }
  }
}

// Extra small devices (portrait phones, less than 576px)
@media (max-width: 575.98px) {
  .category {
    overflow: hidden;
    .category-content {
      overflow: scroll;
      height: 100%;
      padding-bottom: 1.5rem;
      h1 {
        font-size: 2rem;
      }
      h4 {
        font-size: 1.2rem;
      }
      .product-item {
        width: 100%;
      }
      .filter-btn {
        border: 2px solid white;
        position: relative;
        z-index: 11;
      }
      .filter-container {
        border: 1px solid white;
        background-color: $white;
        width: 230px;
        top: 1rem;
        position: absolute;
        padding-bottom: 0.7rem;
        .filter-content {
          border: 2px solid $grey;
          width: 100%;
        }
        .filter-button,
        li.filter-item {
          font-size: 1.2rem;
          // padding-top: 0.2rem;
          width: 100%;
          min-width: 192px;
          color: $red;
          .item-active {
            color: $red;
            background-color: $white;
          }
        }
        li.filter-item {
          color: $red;
          background-color: $grey;
          border-bottom: 0.25px solid $white;
        }

        li.filter-item:last-child {
          border-bottom: none;
        }
        li.filter-item.active {
          background-color: $white;
        }
      }
      .filter-button img.dropdown-icon {
        height: 0.8rem;
        top: 0.5rem;
        transform: translateY(0);
        &.active {
          transform: translateY(0) rotate(-90deg);
        }
      }
      .filter-item-container {
        border-bottom: none;
        position: static;

        // transition: max-height 0.5s;
      }
      .filter-item-container.clicked {
        max-height: 300px;
        border-top: 2px solid $grey;
        border-left: none;
        border-right: none;
        left: -2px;

        transition: max-height 0.5s;
      }
      .clear-filters {
        color: $red;
        font-size: 1.2rem;
        position: static;
      }
      .filter-content:first-child {
        margin-top: 1.4rem;
      }
      .filter-btn-active {
        color: $red;
        background-color: $white;
      }
    }
  }
}

// Medium devices (tablets, 768px and up)
@media (min-width: 768px) and (max-width: 991.98px) and (orientation: portrait) {
  .category {
    .category-content {
      .clear-filters {
        right: auto;
        top: 3rem;
      }
    }
  }
}

// Small devices (landscape phones, 576px and up)
@media (min-width: 568px) and (max-width: 767.98px) and (orientation: landscape),
  (max-width: 812px) and (max-height: 375px) and (orientation: landscape) {
  .category {
    overflow: hidden;
    .category-content {
      overflow: scroll;
      height: 100%;
      padding-bottom: 1.5rem;
      h1 {
        font-size: 2rem;
      }
      h4 {
        font-size: 1.2rem;
      }
      .product-item {
        width: 100%;
      }
      .filter-btn {
        border: 2px solid white;
        position: relative;
        z-index: 11;
      }
      .filter-container {
        border: 1px solid white;
        background-color: $white;
        width: 230px;
        top: 1rem;
        position: absolute;
        padding-bottom: 0.7rem;
        .filter-content {
          border: 2px solid $grey;
          width: 100%;
        }
        .filter-button,
        li.filter-item {
          font-size: 1.2rem;
          // padding-top: 0.2rem;
          width: 100%;
          min-width: 192px;
          color: $red;
          .item-active {
            color: $red;
            background-color: $white;
          }
        }
        li.filter-item {
          color: $red;
          background-color: $grey;
          border-bottom: 0.25px solid $white;
        }
        li.filter-item.active {
          background-color: $white;
        }
      }
      .filter-button img.dropdown-icon {
        height: 0.8rem;
        top: 0.5rem;
        transform: translateY(0);
        &.active {
          transform: translateY(0) rotate(-90deg);
        }
      }
      .filter-item-container {
        border-bottom: none;
        position: static;
      }
      .filter-item-container.clicked {
        max-height: initial;
        border-top: 2px solid $grey;
        border-left: none;
        border-right: none;
        left: -2px;
      }
      .clear-filters {
        color: $red;
        font-size: 1.2rem;
        position: static;
      }
      .filter-content:first-child {
        margin-top: 1.4rem;
      }
      .filter-btn-active {
        color: $red;
        background-color: $white;
      }
    }
  }
}

@media (min-width: 568px) and (max-width: 767.98px) and (orientation: landscape),
  (max-width: 812px) and (max-height: 375px) and (orientation: landscape) {
  .iphone-x-hide {
    display: none !important;
  }

  .iphone-x-show {
    display: flex !important;
  }
}
</style>
