import { florDelSol } from '../brands';

export default [
  {
    product: 'Robustos 10U',
    module: 'Venda ao bundle',
    image: '/img/categories/charuto/florDelSol/robustos10u/product.png',
    barcode: '8720400334315',
    brand: florDelSol,
    filters: {
      intensidade: 'medio',
      vitola: 'robusto',
    },
    properties: [
        { key: "Comprimento", value: "12.7cm" },
        { key: "Diâmetro", value: "2cm" },
        { key: "Folha", value: "Colorado" },
        { key: "Sabor", value: "Forte e picante" },
    ],
  },
  {
    product: 'Short Robustos 10U',
    module: 'Venda ao bundle',
    image: '/img/categories/charuto/florDelSol/shortRobustos10u/product.png',
    barcode: '8720400334322',
    brand: florDelSol,
    filters: {
      intensidade: 'medio',
      vitola: 'shortRobusto',
    },
    properties: [
        { key: "Comprimento", value: "10,2cm" },
        { key: "Diâmetro", value: "2,1cm" },
        { key: "Folha", value: "Colorado" },
        { key: "Sabor", value: "Forte e picante" },
    ],
  },
  // {
  //   product: 'Half Corona 10U',
  //   module: 'Venda ao bundle',
  //   image: '/img/categories/charuto/florDelSol/halfCorona10u/product.png',
  //   barcode: '8720400388325',
  //   brand: florDelSol,
  //   filters: {
  //     intensidade: 'medio',
  //     vitola: 'halfCorona',
  //   },
  //   properties: [
  //       { key: "Comprimento", value: "9cm" },
  //       { key: "Diâmetro", value: "1,7cm" },
  //       { key: "Folha", value: "Colorado" },
  //       { key: "Sabor", value: "Forte e picante" },
  //   ],
  // },
];
