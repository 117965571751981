import { laPaz } from '../brands';

export default [
  {
    product: 'Cigarrillos 10',
    module: 'Módulo: 10 Unidades',
    image: '/img/categories/cigarrilhas/laPaz/cigarrillos10/product.png',
    barcode: '8720400418718',
    brand: laPaz,
    filters: {
      tamanho: 'regular',
      sabor: 'original',
      filtro: 'semFiltro',
    },
    properties: [],
  },
  {
    product: 'Mini Cigarrillos 10',
    module: 'Módulo: 10 Unidades',
    image: '/img/categories/cigarrilhas/laPaz/miniCigarrillos10/product.png',
    barcode: '8720400419197',
    brand: laPaz,
    filters: {
      tamanho: 'regular',
      sabor: 'original',
      filtro: 'semFiltro',
    },
    properties: [],
  },
  {
    product: 'Cigarros 5',
    module: 'Módulo: 5 Unidades',
    image: '/img/categories/cigarrilhas/laPaz/cigarros5/product.png',
    barcode: '8720400427444',
    brand: laPaz,
    filters: {
      tamanho: 'panatella',
      sabor: 'original',
      filtro: 'semFiltro',
    },
    properties: [],
  },
  {
    product: 'Miniaturas 10',
    module: 'Módulo: 10 Unidades',
    image: '/img/categories/cigarrilhas/laPaz/miniaturas10/product.png',
    barcode: '8720400418084',
    brand: laPaz,
    filters: {
      tamanho: 'mini',
      sabor: 'original',
      filtro: 'semFiltro',
    },
    properties: [],
  },
];
