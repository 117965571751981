import { borkumRiff } from '../brands';

export default [
  {
    product: 'Original 35g',
    module: 'Módulo: 5 Onças',
    image: '/img/categories/cachimbo/borkumRiff/original35/product.png',
    barcode: '5710840140426',
    brand: borkumRiff,
    filters: {
      corpo: '2',
      sabor: '3',
    },
    properties: [
        { key: "Nota de aroma", value: "5" },
    ],
  },
  {
    product: 'Ruby 35g',
    module: 'Módulo: 5 Onças',
    image: '/img/categories/cachimbo/borkumRiff/ruby35/product.png',
    barcode: '5710840140303',
    brand: borkumRiff,
    filters: {
      corpo: '2',
      sabor: '3',
    },
    properties: [
        { key: "Nota de aroma", value: "5" },
    ],
  },
  {
    product: 'Sungold 35g',
    module: 'Módulo: 5 Onças',
    image: '/img/categories/cachimbo/borkumRiff/sungold35/product.png',
    barcode: '5710840140730',
    brand: borkumRiff,
    filters: {
      corpo: '2',
      sabor: '3',
    },
    properties: [
        { key: "Nota de aroma", value: "4" },
    ],
  },
  // {
  //   product: 'Black 35g',
  //   module: 'Módulo: 5 Onças',
  //   image: '/img/categories/cachimbo/borkumRiff/black35/product.png',
  //   barcode: '5710840140150',
  //   brand: borkumRiff,
  //   filters: {
  //     corpo: '2',
  //     sabor: '3',
  //   },
  //   properties: [
  //       { key: "Nota de aroma", value: "5" },
  //   ],
  // },
  {
    product: 'Bronze 35g',
    module: 'Módulo: 5 Onças',
    image: '/img/categories/cachimbo/borkumRiff/bronze35/product.png',
    barcode: '5710840140464',
    brand: borkumRiff,
    filters: {
      corpo: '2',
      sabor: '3',
    },
    properties: [
        { key: "Nota de aroma", value: "3" },
    ],
  },
];
