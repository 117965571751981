<template>

    <div class="portfolio vh-100 w-100">
        <!-- NAVBAR -->
        <navbar @open="showMenu=true" />
        <!-- END NAVBAR -->
        <div class="portfolio-content h-100 d-none d-sm-block">
            <!-- CATEGORY -->
            <div
            class="category container white-color d-flex flex-column mb-4"
            v-for="(category, idx) in categories"
            :key="idx">
                <!-- TITLE -->
                <div class="category-title d-flex justify-content-center">
                    <h1 class="align-self-center">{{ category.name }}</h1>
                </div>
                <!-- END TITLE -->
                <!-- BRANDS -->
                <div class="brands-container d-flex flex-wrap justify-content-center">
                    <div class="brand grey-border d-flex align-items-center justify-content-center" :class="{ draw: brand.isActive }" v-for="(brand, idxx) in category.brands" :key="idxx" @click="goTo(brand)">
                        <img :src="brand.image" :alt="brand.name">
                    </div>
                </div>
                <!-- END BRANDS -->
            </div>
            <!-- END CATEGORY -->
        </div>
        <!-- MOBILE -->
        <div class="portfolio-content-mobile h-100 d-block d-sm-none">
            <!-- CATEGORY -->
            <div class="container white-color d-flex flex-column mb-2" v-for="(category, idx) in categories" :key="idx">
                <div class="row">
                    <div class="col-12">
                        <!-- TITLE -->
                        <div class="category-title d-flex justify-content-center">
                            <h1 class="mb-2 align-self-center">{{ category.name }}</h1>
                        </div>
                        <!-- END TITLE -->
                    </div>
                </div>
                <!-- BRANDS -->
                <div class="brands-container d-flex flex-wrap justify-content-center">
                    <div class="brand d-flex align-items-center justify-content-center" :class="{ draw: brand.isActive }" v-for="(brand, idxx) in category.brands" :key="idxx" @click="goTo(brand)">
                        <img :src="brand.image" :alt="brand.name">
                    </div>
                </div>
                <!-- END BRANDS -->
            </div>
            <!-- END CATEGORY -->
        </div>
        <!-- END MOBILE -->
        <transition name="fade">
            <loading v-if="showLoading" :showloadingicon="controlLoadingIcon" />
        </transition>
    </div>

</template>

<script>

import {
  getCategories,
  getBrandsByCategory
} from '@/providers/portfolio';

import navbar from '@/components/Navbar.vue';
import loading from '@/components/Loading.vue';

export default {
    name: 'portfolio',
    components: {
      navbar,
      loading,
    },
    data: function() {
        return {
            categories: [],
            brandClicked: null,
            showLoading: false,
            controlLoadingIcon: false,
        }
    },
    methods: {
      goTo: function(brand) {
        this.showLoading = true;
        brand.isActive = true;
        this.$forceUpdate();
        setTimeout(() => {
          this.brandClicked = brand;
          this.$router.push({ name:'brand', params: { brand: brand.slug } });
          brand.isActive = false;
        }, 300);
      }
    },
    beforeMount() {
        this.categories = getCategories();
        _.forEach(this.categories, (value, key) => {
            this.categories[key].brands = getBrandsByCategory(value.slug);
        });
    },
};

</script>

<style lang="scss" scoped>

.draw {
  position: relative;
}

.draw::before, .draw::after {
  content:'';
  position: absolute;
  border: 4px solid $red;
  height: calc(100% + 8px);
  height: -webkit-calc(100% + 8px);
  height: -moz-calc(100% + 8px);
  width: calc(100% + 8px);
  width: -webkit-calc(100% + 8px);
  width: -moz-calc(100% + 8px);
}

.draw::before {
  left: -4px;
  top: -4px;
  border: 4px solid transparent;
  animation: draw-before 0.15s ease-out;
  animation-fill-mode: forwards;
}

.draw::after {
  right: -4px;
  bottom: -4px;
  border: 4px solid transparent;
  animation: draw-after 0.15s ease-out 0.15s;
  animation-fill-mode: forwards;
}

@keyframes draw-before {
  0% {
    width: 0;
    height: 0;
    border-top-color: $red;
  }
  50% {
    width: calc(100% + 8px);
    width: -webkit-calc(100% + 8px);
    width: -moz-calc(100% + 8px);
    height: 0;
    border-top-color: $red;
    border-right-color: $red;
  }
  100% {
    height: calc(100% + 8px);
    height: -webkit-calc(100% + 8px);
    height: -moz-calc(100% + 8px);
    border-top-color: $red;
    border-right-color: $red;
  }
}

@keyframes draw-after {
  0% {
    width: 0;
    height: 0;
    border-bottom-color: $red;
  }
  50% {
    width: calc(100% + 8px);
    width: -webkit-calc(100% + 8px);
    width: -moz-calc(100% + 8px);
    height: 0;
    border-left-color: $red;
    border-bottom-color: $red;
  }
  100% {
    height: calc(100% + 8px);
    height: -webkit-calc(100% + 8px);
    height: -moz-calc(100% + 8px);
    border-left-color: $red;
    border-bottom-color: $red;
  }
}

.portfolio {
    background: url('/img/bg-brands.jpg') center/cover no-repeat;
    overflow: hidden;
    .portfolio-content {
        overflow: scroll;
        -webkit-overflow-scrolling: touch;
        padding: 3rem 0 6rem;
        /* TITLE */
        .category-title {
            h1 {
                font-weight: bold;
                font-size: 4.5rem;
                margin-bottom: 2rem;
            }
        }
        /* BRAND */
        .brand {
            margin: 0 1rem 1rem 0;
            width: 180px;
            height: 150px;
            img {
                max-width: 100%;
                padding: 0 5px;
            }
        }
    }
}

// Extra small devices (portrait phones, less than 576px)
@media (max-width: 575.98px) {

  .draw::before, .draw::after {
    content:'';
    border: 3px solid $red;
    height: calc(100% + 6px);
    height: -webkit-calc(100% + 6px);
    height: -moz-calc(100% + 6px);
    width: calc(100% + 6px);
    width: -webkit-calc(100% + 6px);
    width: -moz-calc(100% + 6px);
  }

  .draw::before {
    border: 3px solid transparent;
    left: -3px;
    top: -3px;
  }

  .draw::after {
    border: 3px solid transparent;
    right: -3px;
    bottom: -3px;
  }

  @keyframes draw-before {
    0% {
      width: 0;
      height: 0;
      border-top-color: $red;
    }
    50% {
      width: calc(100% + 6px);
      width: -webkit-calc(100% + 6px);
      width: -moz-calc(100% + 6px);
      height: 0;
      border-top-color: $red;
      border-right-color: $red;
    }
    100% {
      height: calc(100% + 6px);
      height: -webkit-calc(100% + 6px);
      height: -moz-calc(100% + 6px);
      border-top-color: $red;
      border-right-color: $red;
    }
  }

  @keyframes draw-after {
    0% {
      width: 0;
      height: 0;
      border-bottom-color: $red;
    }
    50% {
      width: calc(100% + 6px);
      width: -webkit-calc(100% + 6px);
      width: -moz-calc(100% + 6px);
      height: 0;
      border-left-color: $red;
      border-bottom-color: $red;
    }
    100% {
      height: calc(100% + 6px);
      height: -webkit-calc(100% + 6px);
      height: -moz-calc(100% + 6px);
      border-left-color: $red;
      border-bottom-color: $red;
    }
  }

    .portfolio-content-mobile {
        overflow: scroll;
        padding: 1rem 0 5rem;
        /* TITLE */
        .category-title {
            h1 {
                font-weight: bold;
                font-size: 2rem;
            }
        }
        /* BRAND */
        .brand {
            border: 3px solid $grey;
            margin: 0 0.5rem 0.5rem 0;
            width: 120px;
            height: 120px;
            img {
                max-width: 100%;
                padding: 0 5px;
            }
        }
    }
}

// Small devices (landscape phones, 576px and up)
@media (min-width: 568px) and (max-width: 767.98px) and (orientation: landscape), (max-width: 812px) and (max-height: 375px) and (orientation: landscape) {

  .draw::before, .draw::after {
    content:'';
    border: 3px solid $red;
    height: calc(100% + 6px);
    height: -webkit-calc(100% + 6px);
    height: -moz-calc(100% + 6px);
    width: calc(100% + 6px);
    width: -webkit-calc(100% + 6px);
    width: -moz-calc(100% + 6px);
  }

  .draw::before {
    border: 3px solid transparent;
    left: -3px;
    top: -3px;
  }

  .draw::after {
    border: 3px solid transparent;
    right: -3px;
    bottom: -3px;
  }

  @keyframes draw-before {
    0% {
      width: 0;
      height: 0;
      border-top-color: $red;
    }
    50% {
      width: calc(100% + 6px);
      width: -webkit-calc(100% + 6px);
      width: -moz-calc(100% + 6px);
      height: 0;
      border-top-color: $red;
      border-right-color: $red;
    }
    100% {
      height: calc(100% + 6px);
      height: -webkit-calc(100% + 6px);
      height: -moz-calc(100% + 6px);
      border-top-color: $red;
      border-right-color: $red;
    }
  }

  @keyframes draw-after {
    0% {
      width: 0;
      height: 0;
      border-bottom-color: $red;
    }
    50% {
      width: calc(100% + 6px);
      width: -webkit-calc(100% + 6px);
      width: -moz-calc(100% + 6px);
      height: 0;
      border-left-color: $red;
      border-bottom-color: $red;
    }
    100% {
      height: calc(100% + 6px);
      height: -webkit-calc(100% + 6px);
      height: -moz-calc(100% + 6px);
      border-left-color: $red;
      border-bottom-color: $red;
    }
  }

    .portfolio {
        .portfolio-content {
            padding: 0rem 0rem 4rem;
            .category {
                flex-direction: column;
                /* TITLE */
                .category-title {
                    h1 {
                        font-weight: bold;
                        font-size: 2rem;
                        margin-bottom: 1rem !important;
                    }
                }
                .brands-container {
                    justify-content: center !important;
                    /* BRAND */
                    .brand {
                        border: 3px solid $grey;
                        margin: 0 0.5rem 0.5rem 0;
                        width: 120px;
                        height: 120px;
                        img {
                            max-width: 100%;
                            padding: 0 5px;
                        }
                    }
                }
            }
        }
    }
}

</style>
