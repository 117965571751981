const cigarrilhas = [
  {
    category: { slug: 'tamanho', name: 'Tamanho' },
    items: [
      { slug: 'regular', name: 'Regular' },
      { slug: 'mini', name: 'Mini' },
      { slug: 'panatella', name: 'Panatella' },
    ],
  },
  {
    category: { slug: 'sabor', name: 'Sabor' },
    items: [
      { slug: 'aromatico', name: 'Aromático' },
      { slug: 'suave', name: 'Suave' },
      { slug: 'extraSuave', name: 'Extra suave' },
      { slug: 'original', name: 'Original' },
      { slug: 'mentol', name: 'Mentol' },
    ],
  },
  {
    category: { slug: 'filtro', name: 'Filtro' },
    items: [
      { slug: 'semFiltro', name: 'Sem filtro' },
      { slug: 'comFiltro', name: 'Com filtro' },
    ],
  },
];

const cachimbo = [
  {
    category: { slug: 'corpo', name: 'Corpo' },
    items: [
      { slug: '1', name: '1' },
      { slug: '2', name: '2' },
      { slug: '3', name: '3' },
    ],
  },
  {
    category: { slug: 'sabor', name: 'Sabor aromático' },
    items: [
      { slug: '1', name: '1' },
      { slug: '2', name: '2' },
      { slug: '3', name: '3' },
      { slug: '4', name: '4' },
    ],
  },
  // {
  //   category: { slug: 'aroma', name: 'Nota de aroma' },
  //   items: [
  //     { slug: '2', name: '2' },
  //     { slug: '3', name: '3' },
  //     { slug: '4', name: '4' },
  //     { slug: '5', name: '5' },
  //   ],
  // },
];

const charuto = [
  {
    category: { slug: 'intensidade', name: 'Intensidade' },
    items: [
      { slug: 'suaveMedio', name: 'Suave-Médio' },
      { slug: 'medio', name: 'Médio' },
      { slug: 'medioForte', name: 'Médio-Forte' },
      { slug: 'forte', name: 'Forte' },
    ],
  },
  {
    category: { slug: 'vitola', name: 'Vitola' },
    items: [
      { slug: 'halfCorona', name: 'Half Corona' },
      { slug: 'shortRobusto', name: 'Short Robusto' },
      { slug: 'marevas', name: 'Marevas' },
      { slug: 'robusto', name: 'Robusto' },
      { slug: 'rothschild', name: 'Rothschild' },
      { slug: 'toro', name: 'Toro' },
      { slug: 'gigante', name: 'Gigante' },
    ],
  },
];

const enrolar = [
  {
    category: { slug: 'sabor', name: 'Sabor' },
    items: [
      { slug: 'original', name: 'Original' },
      { slug: 'suave', name: 'Suave' },
      { slug: 'virginia', name: 'Virginia' },
    ],
  },
];

export {
  cigarrilhas,
  cachimbo,
  charuto,
  enrolar,
};
