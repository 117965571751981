<template>

    <div ref="brandproducts" class="brand-products h-100 w-100 d-flex flex-column align-items-center" :style="{ backgroundImage: 'url(/img/categories/'+brand.category.slug+'/bg.jpg)' }">
        <navbar class="align-self-stretch"/>
        <div class="brand-products-container container d-flex flex-column position-relative flex-grow-1">
            <!-- BRAND LOGO -->
            <img class="logo align-self-center mb-2 mb-md-5" :src="brand.image" :alt="brand.name">
            <!-- END BRAND LOGO -->
            <!-- CAROUSEL -->
            <div class="carousel-container">
                <carousel-3d class="m-0" :perspective="0" :space="carouselSpace" :display="3" :height="carouselHeight" dir="ltr" :onMainSlideClick="clickedProductHandler">
                    <slide v-for="(product, idx) in brandProducts" :index="idx" :key="idx">
                        <img class="mb-3" :src="product.image" :alt="product.product">
                        <h3 class="mb-0 d-none d-sm-block"><strong>{{product.brand.name}}</strong> {{ product.product }}</h3>
                        <h3 class="mb-0 d-block d-sm-none"><strong>{{product.brand.name}}</strong></h3>
                        <h3 class="mb-0 d-block d-sm-none">{{ product.product }}</h3>
                    </slide>
                </carousel-3d>
            </div>
            <!-- END CAROUSEL -->
        </div>
        <!-- POPUP -->
        <transition name="fade">
            <product-popup :product="activeProduct" v-if="showPopup" @close="showPopup=false" />
        </transition>
        <!-- END POPUP -->
    </div>

</template>

<script>

import {
    getBrand,
    getProductsByBrand
} from '@/providers/portfolio';

import navbar from '@/components/Navbar.vue';
import productPopup from '@/components/ProductPopup.vue';

import {
    Carousel3d,
    Slide
} from 'vue-carousel-3d';

import _ from 'lodash';

export default {
    name: 'brand-products',
    data: function() {
        return {
            brandProducts: [],
            brand: {},
            showPopup: false,
            activeProduct: {},
        }
    },
    methods: {
        clickedProductHandler: function () {
            const currentCarouselItem = _.find(this.$children[1].$children, child => child.isCurrent);
            const currentProduct = this.brandProducts[currentCarouselItem.index];
            this.activeProduct = currentProduct;
            this.showPopup = true;
        }
    },
    components: {
        Carousel3d,
        Slide,
        navbar,
        productPopup,
    },
    beforeMount() {
        this.brandProducts = getProductsByBrand(this.$route.params.brand);
        this.brand = getBrand(this.$route.params.brand);
    },
    computed: {
        carouselSpace: function () {
            // return window.innerWidth < 575.98 ? 100 : 400;
            if (window.innerWidth >= 768 && window.innerHeight > window.innerWidth ) {
                return 325;
            } else if (window.innerWidth >= 568 && window.innerWidth <= 768 && window.innerHeight < window.innerWidth || window.innerWidth >= 568 && window.innerWidth <= 812 && window.innerHeight <= 375 && window.innerHeight < window.innerWidth ) {
                return 125;
            } else if (window.innerWidth < 575.98) {
                return 100;
            } else {
                return 400;
            }
        },
        carouselHeight: function () {
            // return window.innerWidth < 575.98 ? 300 : 400;
            if (window.innerWidth >= 568 && window.innerWidth <= 768 && window.innerHeight < window.innerWidth || window.innerWidth >= 568 && window.innerWidth <= 812 && window.innerHeight <= 375 && window.innerHeight < window.innerWidth ) {
                return 250;
            } else if (window.innerWidth < 575.98) {
                return 300;
            } else {
                return 412;
            }
        },
    },
    mounted() {
      this.$refs.brandproducts.addEventListener('touchmove', function(e) {
          e.preventDefault();
          e.stopPropagation();
      });
    },
};

</script>

<style lang="scss" scoped>

.h-100 {
    min-height: 100vh;
}

.brand-products {
    background: url('/img/bg-brand-product.jpg') center/cover no-repeat;
    padding: 0 0 3.5rem;
    color: $white;
    .brand-products-container {
        justify-content: center;
        /* BRAND LOGO */
        img.logo {
            width: 100%;
            max-width: 350px;
            max-height: 200px;
            object-fit: contain;
        }
        /* CAROUSEL */
        .carousel-container {
            .carousel-3d-slide {
                background-color: transparent;
                text-align: center;
                border: none;
                transition: opacity 0.4s;
                img {
                    width: 100%;
                    max-height: 350px;
                    object-fit: contain;
                }
            }
            .carousel-3d-slide.left-1, .carousel-3d-slide.right-1 {
                opacity: 0.75 !important;
                h3 {
                    display: none !important;
                }
            }
        }
    }
}

// Extra small devices (portrait phones, less than 576px)
@media (max-width: 575.98px) {
    .brand-products {
        padding: 0;
        .brand-products-container {
            justify-content: center;
            img.logo {
                max-width: 180px;
                max-height: 100px;
            }
            .carousel-container {
                .carousel-3d-slide {
                    img {
                        max-height: 150px;
                        object-fit: contain;
                    }
                    h3 {
                        font-size: 1.1rem;
                    }
                }
            }
        }
    }
}

// Small devices (landscape phones, 576px and up)
@media (min-width: 568px) and (max-width: 767.98px) and (orientation: landscape), (max-width: 812px) and (max-height: 375px) and (orientation: landscape) {
    .brand-products {
        padding: 0;
        .brand-products-container {
            justify-content: center;
            img.logo {
                max-width: 180px;
                max-height: 100px;
            }
            .carousel-container {
                .carousel-3d-slide {
                    img {
                        max-height: 150px;
                        object-fit: contain;
                    }
                    h3 {
                        font-size: 1.1rem;
                    }
                }
            }
        }
    }
}

</style>
