<template>

    <div class="brand beige-color h-100 w-100 d-flex flex-column pb-3">
        <navbar />
        <div class="container d-flex flex-column align-items-center justify-content-between flex-grow-1">
            <!-- INVISIBLE -->
            <div class="row invisible">
                <router-link tag="a" class="button white-color white-border" :to="{ name: 'brandProducts', params: { brand: brand.slug }}">Ver produtos</router-link>
            </div>
            <!-- END INVISIBLE -->
            <!-- BRAND -->
            <div class="row land-grow-center">
                <div class="col-12">
                    <div class="logo-container">
                        <img class="mb-2" :src="brand.image" :alt="brand.name">
                        <h3 class="mt-md-5 mb-md-5 mt-0 mb-3" v-html="brand.description"></h3>
                    </div>
                </div>
            </div>
            <!-- END BRAND -->
            <!-- BUTTON -->
            <div class="container button-container btn-animated">
                <div class="row">
                    <div class="col-12">
                        <!-- <router-link tag="a" class="white-color white-border" :class="{ draw: btnIsActive }" :to="{ name: 'brandProducts', params: { brand: brand.slug }}">Ver produtos</router-link> -->
                        <a class="white-color white-border bttn" :class="{ draw: btnIsActive }" @click="goToBrand(brand.slug)">Ver produtos</a>
                    </div>
                </div>
            </div>
            <!-- END BUTTON -->
            <transition name="fade">
                <loading v-if="showLoading" :showloadingicon="controlLoadingIcon" />
            </transition>
        </div>
    </div>
</div>

</template>

<script>

import {
  getBrand,
} from '@/providers/portfolio';

import navbar from '@/components/Navbar.vue';
import loading from '@/components/Loading.vue';

export default {
    name: 'brand',
    components: {
        navbar,
        loading,
    },
    data: function () {
        return {
            brand: {},
            btnIsActive: false,
            showLoading: false,
            controlLoadingIcon: false,
        }
    },
    methods: {
      goToBrand: function (brand) {
        this.btnIsActive = true;
        this.showLoading = true;
        setTimeout(() => {
          this.$router.push({ name:'brandProducts', params: { brand: brand } });
        }, 350);
      },
    },
    beforeMount() {
        this.brand = getBrand(this.$route.params.brand);
    },
};

</script>

<style lang="scss" scoped>

.brand {
    background: url('/img/bg-brand-product.jpg') center/cover no-repeat;
    &.h-100 {
        min-height: 100vh;
    }
    /* BRAND */
    .logo-container img {
        max-width: 500px;
        width: 100%;
        max-height: 250px;
        object-fit: contain;
    }
    // BUTTON
    .btn-animated {
        animation: moveInBottom .5s ease-out 0.3s;
        -webkit-animation: moveInBottom .5s ease-out 0.3s;
        animation-fill-mode: backwards;
    }
}

// Extra small devices (portrait phones, less than 576px)
@media (max-width: 575.98px) {
    .brand {
        /* LOGO */
        .logo-container img {
            max-width: 180px;
            max-height: 100px;
        }
        /* BRAND */
        h3 {
            font-size: 1.1rem;
        }
    }
}

// Small devices (landscape phones, 576px and up)
@media (min-width: 568px) and (max-width: 767.98px) and (orientation: landscape), (max-width: 812px) and (max-height: 375px) and (orientation: landscape) {
    .invisible {
        display: none;
    }
    .land-grow-center {
        flex-grow: 1;
        align-items: center;
    }
    .brand {
        /* LOGO */
        .logo-container img {
            max-width: 180px;
            max-height: 100px;
        }
        /* BRAND */
        h3 {
            font-size: 1.1rem;
        }
    }
}

</style>
