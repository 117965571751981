import { skandinavik } from '../brands';

export default [
  {
    product: 'Mixture 35g',
    module: 'Módulo: 5 Onças',
    image: '/img/categories/cachimbo/skandinavik/mixture35/product.png',
    barcode: '5710840140648',
    brand: skandinavik,
    filters: {
      corpo: '2',
      sabor: '2',
    },
    properties: [
        { key: "Nota de aroma", value: "3" },
    ],
  },
  // {
  //   product: 'Sungold 35g',
  //   module: 'Módulo: 5 Onças',
  //   image: '/img/categories/cachimbo/skandinavik/sungold35/product.png',
  //   barcode: '5710840140679',
  //   brand: skandinavik,
  //   filters: {
  //     corpo: '1',
  //     sabor: '1',
  //   },
  //   properties: [
  //       { key: "Nota de aroma", value: "4" },
  //   ],
  // },
  {
    product: 'Navy 35g',
    module: 'Módulo: 5 Onças',
    image: '/img/categories/cachimbo/skandinavik/navy35/product.png',
    barcode: '5710840140617',
    brand: skandinavik,
    filters: {
      corpo: '2',
      sabor: '3',
    },
    properties: [
        { key: "Nota de aroma", value: "4" },
    ],
  },
];
