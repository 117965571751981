import { cao } from '../brands';

export default [
  {
    product: 'Pilón Robusto 1U',
    module: 'Venda à unidade',
    image: '/img/categories/charuto/cao/robusto1u/product.png',
    barcode: '5600715274120',
    brand: cao,
    filters: {
      intensidade: 'medioForte',
      vitola: 'robusto',
    },
    properties: [
        { key: "Comprimento", value: "12,7cm" },
        { key: "Diâmetro", value: "2,1cm" },
        { key: "Folha", value: "Colorado" },
        { key: "Sabor", value: "Forte e picante" },
    ],
  },
  // {
  //   product: 'Pilón Toro 1U',
  //   module: 'Venda à unidade',
  //   image: '/img/categories/charuto/cao/toro1u/product.png',
  //   barcode: '5600715274137',
  //   brand: cao,
  //   filters: {
  //     intensidade: 'medioForte',
  //     vitola: 'toro',
  //   },
  //   properties: [
  //       { key: "Comprimento", value: "15,2cm" },
  //       { key: "Diâmetro", value: "2,3cm" },
  //       { key: "Folha", value: "Colorado" },
  //       { key: "Sabor", value: "Forte e picante" },
  //   ],
  // },
  {
    product: 'Pilón Robusto Extra 1U',
    module: 'Venda à unidade',
    image: '/img/categories/charuto/cao/robustoExtra1u/product.png',
    barcode: '8720400484935',
    brand: cao,
    filters: {
      intensidade: 'medioForte',
      vitola: 'robusto',
    },
    properties: [
        { key: "Comprimento", value: "12,7cm" },
        { key: "Diâmetro", value: "2,1cm" },
        { key: "Folha", value: "Colorado" },
        { key: "Sabor", value: "Forte e picante" },
    ],
  },
];
